import React, { useEffect, useMemo, useState } from "react";

import BillingList from "./BillingList";
import CustomDateSelector from "../../components/CustomDateSelector";
import { useSelector } from "react-redux";
import {
  USER_ROLE_AGENT,
  USER_ROLE_ENTERPRISE,
  USER_ROLE_MEMBER,
} from "../../common/constants";

const Billing = () => {
  const [rangeDate, setRangeDate] = useState([]);
  const [durationSelect, setDurationSelect] = useState("");

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (
      [USER_ROLE_MEMBER, USER_ROLE_AGENT, USER_ROLE_ENTERPRISE].includes(
        user?.role
      )
    ) {
      window.location.href = "/";
    }
  }, [user?.role]);

  const currentDate = new Date();
  let startDate, endDate;

  useEffect(() => {
    if (rangeDate?.[1] !== undefined) {
      setDurationSelect("Custom");
    }
  }, [rangeDate]);

  switch (durationSelect) {
    case "This_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      endDate.setHours(23, 59, 59, 999);
      break;
    case "Last_Month":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "This_Week":
      const firstDayOfWeek = currentDate.getDate() - currentDate.getDay();
      startDate = new Date(currentDate);
      startDate.setDate(firstDayOfWeek);
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(currentDate);
      endDate.setDate(firstDayOfWeek + 6);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "This_Year":
      startDate = new Date(currentDate.getFullYear(), 0, 1);
      endDate = new Date(currentDate.getFullYear(), 11, 31);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      break;
    case "Today":
      startDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate()
      );
      startDate.setHours(0, 0, 0, 0);
      endDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        23,
        59,
        59
      );
      endDate.setHours(23, 59, 59, 999);
      break;
    case "Custom":
      startDate = new Date(rangeDate?.[0] || null);
      endDate = new Date(rangeDate?.[1] || null);
      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);

      break;

    default:
      startDate = null;
      endDate = null;
      break;
  }

  // const query = useMemo(() => {
  //   return {
  //     startDate: startDate
  //       ? moment(startDate).format("YYYY-MM-DD HH:mm:ss")
  //       : "",
  //     endDate: endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : "",
  //   };
  // }, [startDate, endDate]);

  const query = useMemo(() => {
    return {
      startDate: startDate ? startDate?.toISOString() : "",
      endDate: endDate ? endDate?.toISOString() : "",
    };
  }, [startDate, endDate]);

  return (
    <div className="lg:mx-32 md:mx-16">
      <div className="row">
        <div className="col-xl-12">
          <CustomDateSelector
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
            setDurationSelect={setDurationSelect}
          />
        </div>
        <BillingList query={query} />
      </div>
    </div>
  );
};

export default Billing;
