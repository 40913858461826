import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button, MenuItem, Switch, TextField } from "@mui/material";
import { Formik } from "formik";
import { Modal } from "react-bootstrap";

const UpdateCreditLimitModal = ({
  openAddLimit,
  setOpenAddLimit,
  billing,
  handleLimit,
}) => {
  return (
    <Modal
      className="modal fade"
      show={openAddLimit}
      onHide={setOpenAddLimit}
      size="md"
    >
      <div className="" role="document">
        <div className="">
          <div className="modal-header">
            <h4 className="modal-title fs-20">Update Credit Limit</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpenAddLimit(false)}
              data-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            <i className="text-black flat-icon-cancel-12 close"></i>
            <div className="add-contact-box">
              <Formik
                initialValues={{
                  kachaCredit: billing?.company?.kachaCredit ?? false,
                  creditLimit: billing?.company?.totalCreditLimit ?? 0,
                  creditType: billing?.company?.creditType ?? "none",
                  USDCreditType: billing?.company?.USDCreditType ?? "none",
                  USDCreditLimit: billing?.company?.USDTotalCreditLimit ?? 0,
                }}
                onSubmit={handleLimit}
                // validationSchema={EmployeeSchema}
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className="flex flex-col justify-center">
                      <div className="row">
                        <div className="mb-8">
                          <Switch
                            checked={values.kachaCredit}
                            onChange={handleChange}
                            name="kachaCredit"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <label htmlFor="kachaCredit" className="text-black">
                            Kacha Credit (
                            {values.kachaCredit ? "True" : "False"})
                          </label>
                        </div>
                        <div className="mb-8">
                          <TextField
                            fullWidth
                            id="creditType"
                            type="text"
                            size="small"
                            variant="outlined"
                            name="creditType"
                            placeholder="Credit Type"
                            label="Credit Type"
                            select
                            value={values.creditType}
                            onChange={handleChange}
                            defaultValue="none"
                          >
                            <MenuItem value="none">none</MenuItem>
                            <MenuItem value="oneDay">One Day Credit</MenuItem>
                            <MenuItem value="oneWeek" disabled>
                              One Week Credit
                            </MenuItem>
                            <MenuItem value="twoWeek" disabled>
                              Two Week Credit
                            </MenuItem>
                            <MenuItem value="oneMonth" disabled>
                              One Month Credit
                            </MenuItem>
                          </TextField>
                        </div>

                        <div className="mb-8">
                          <TextField
                            fullWidth
                            id="creditLimit"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="creditLimit"
                            placeholder="Credit Limit"
                            label="Credit Limit"
                            // disabled={checker?.data?.user?.email ?? false}
                            value={values.creditLimit}
                            onChange={handleChange}
                            required={values.creditType !== "none"}
                            disabled={values.creditType === "none"}
                            // error={touched.email && Boolean(errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                        </div>

                        <div className="mb-8">
                          <TextField
                            fullWidth
                            id="USDCreditType"
                            type="text"
                            size="small"
                            variant="outlined"
                            name="USDCreditType"
                            placeholder="USD Credit Type"
                            label="USD Credit Type"
                            select
                            value={values.USDCreditType}
                            onChange={handleChange}
                            defaultValue="none"
                          >
                            <MenuItem value="none">none</MenuItem>
                            <MenuItem value="oneDay">One Day Credit</MenuItem>
                            <MenuItem value="oneWeek" disabled>
                              One Week Credit
                            </MenuItem>
                            <MenuItem value="oneMonth" disabled>
                              One Month Credit
                            </MenuItem>
                          </TextField>
                        </div>

                        <div className="mb-8">
                          <TextField
                            fullWidth
                            id="USDCreditLimit"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="USDCreditLimit"
                            placeholder="USD Credit Limit"
                            label="USD Credit Limit"
                            // disabled={checker?.data?.user?.email ?? false}
                            value={values.USDCreditLimit}
                            onChange={handleChange}
                            required={values.USDCreditType !== "none"}
                            disabled={values.USDCreditType === "none"}
                            // error={touched.email && Boolean(errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                        </div>
                      </div>
                      <LoadingButton
                        className="mt-8"
                        variant="contained"
                        type="submit"
                        // loading={add?.loading}
                      >
                        Update
                      </LoadingButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className="modal-footer">
            <Button onClick={() => setOpenAddLimit(false)} variant="danger">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateCreditLimitModal;
