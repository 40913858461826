import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import moment from "moment";

import { TicketPrint } from "../../Pages/Home/Components/TicketPrint";

export const DirectPrint = React.forwardRef(
  (
    {
      passengerList,
      ticketNumbers,
      airReservation,
      flightDestination,
      passengerInfo,
      companyInfo,
      travelers,
      reservation,
    },
    componentRef
  ) => {
    // const componentRef = useRef();
    const componentRef2 = useRef();

    const reactToPrintContent = React.useCallback(() => {
      return componentRef.current;
      // return printWrapperRef.current;
    }, [componentRef]);

    const handlePrint = useReactToPrint({
      content: reactToPrintContent,
    });

    let fareInfo = airReservation?.AirPricingInfo;
    let seatAssignment = [];

    if (travelers instanceof Array) {
      travelers.forEach((traveler, index) => {
        if (!seatAssignment[index]) {
          seatAssignment[index] = [];
        }
        if (traveler?.AirSeatAssignment instanceof Array) {
          traveler?.AirSeatAssignment.forEach((seat) => {
            if (seat?._attributes?.Status === "HK") {
              seatAssignment?.[index].push(seat?._attributes?.Seat);
            }
          });
        } else {
          if (traveler?.AirSeatAssignment?._attributes?.Status === "HK") {
            seatAssignment?.[index].push(
              traveler?.AirSeatAssignment?._attributes?.Seat
            );
          }
        }
      });
    } else {
      if (travelers?.AirSeatAssignment instanceof Array) {
        travelers?.AirSeatAssignment.forEach((seat) => {
          if (seat?._attributes?.Status === "HK") {
            seatAssignment.push(seat?._attributes?.Seat);
          }
        });
      } else {
        if (travelers?.AirSeatAssignment?._attributes?.Status === "HK") {
          seatAssignment.push(travelers?.AirSeatAssignment?._attributes?.Seat);
        }
      }
    }

    // if (selectedSeat instanceof Array) {
    //   selectedSeat.forEach((seat) => {
    //     if (seat?._attributes?.Status === "HK") {
    //       seatAssignment.push(seat?._attributes?.Seat);
    //     }
    //   });
    // } else {
    //   if (selectedSeat?._attributes?.Status === "HK") {
    //     seatAssignment.push(selectedSeat?._attributes?.Seat);
    //   }
    // }

    return (
      <>
        <div className="my-4 xl:mx-80">
          <div>
            <div style={{ pageBreakAfter: "always" }}>
              <TicketPrint
                passengerList={passengerList}
                airReservation={airReservation}
                ticketNumbers={ticketNumbers}
                flightDestination={flightDestination}
                passengerInfo={passengerInfo}
                ref={componentRef}
                companyInfo={companyInfo?._id}
                seatAssignment={seatAssignment}
                reservation={reservation}
              />
            </div>
            {/* <div ref={componentRef2} className="mx-10 text-xs">
              <>
                <div className="p-2 mx-auto mt-4 border-black border-1">
                  <h1 className="py-2 font-semibold text-md">Traveler</h1>
                  <div className="flex flex-row justify-between">
                    <div>
                      <h2 className="font-semibold">Passenger Name:</h2>
                      {passengerList.map((passenger, index) => (
                        <div className="">
                          <div className="space-y-1">
                            {passenger?.Middle ? (
                              <h3 key={index}>
                                {passenger?.Prefix && passenger?.Prefix + ". "}
                                {passenger?.First +
                                  " " +
                                  passenger?.Middle +
                                  " " +
                                  passenger?.Last}
                              </h3>
                            ) : (
                              <h3>
                                {passenger?.Prefix && passenger?.Prefix + ". "}
                                {passenger?.First + " " + passenger?.Last}
                              </h3>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                    <div>
                      <h2 className="font-semibold">e-Ticket Number:</h2>
                      <div className="">
                        {ticketNumbers?.map((ticket) => (
                          <h3>{ticket}</h3>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h2 className="font-semibold">Reservation Number:</h2>
                      <h3>{airReservation?._attributes?.LocatorCode}</h3>
                    </div>
                    <div>
                      <h2 className="font-semibold">Ticket Issue Date:</h2>
                      <h3>
                        {moment(airReservation?._attributes?.CreateDate).format(
                          "DD-MM-YYYY"
                        )}
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="p-2 mx-auto my-2 border-black border-1">
                  <h1 className="py-2 font-semibold text-md">Agency Detail</h1>
                  <div className="flex gap-4">
                    <div className="w-1/4">
                      <div className="space-y-1">
                        <h2 className="font-semibold">Agency Information:</h2>
                        <h3>Sterling Travel and Tour Agency</h3>
                        <h3>Head Office: Near the National Theater </h3>
                        <h3>Addis Ababa</h3>
                        <h3>Ethiopia</h3>
                      </div>
                    </div>
                    <div className="w-1/4 tracking-wide">
                      <div>
                        <h2 className="font-semibold">Agency Phone:</h2>
                        <h3>+251 115 511333 </h3>
                        <h3>+251 115 514666</h3>
                      </div>
                    </div>
                    <div className="w-1/4 tracking-wide">
                      <div>
                        <h2 className="font-semibold">Agency Mobile Phone:</h2>
                        <h3>+251 922 238193</h3>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="p-2 mx-auto my-2 border-black border-1">
                  <h1 className="py-2 font-semibold text-md">
                    Flight Information
                  </h1>
                  <div className="flex flex-col">
                    {passengerList.length > 1
                      ? passengerList?.map((_, i) =>
                          airReservation?.AirSegment instanceof Array
                            ? airReservation?.AirSegment.map((item, index) => {
                                return FlightDetail(
                                  fareInfo?.[i]?.FareInfo[index] ||
                                    fareInfo?.FareInfo[index],
                                  item?._attributes,
                                  i
                                );
                              })
                            : FlightDetail(
                                fareInfo[i]?.FareInfo,
                                airReservation?.AirSegment._attributes
                              )
                        )
                      : airReservation?.AirSegment instanceof Array
                      ? airReservation?.AirSegment.map((item, index) => {
                          return FlightDetail(
                            fareInfo?.FareInfo[index],
                            item._attributes
                          );
                        })
                      : FlightDetail(
                          fareInfo?.FareInfo,
                          airReservation?.AirSegment._attributes
                        )}
                  </div>
                </div>

                <div className="p-2 mx-auto my-2 border-black border-1">
                  <h1 className="py-2 font-semibold text-md">Notes</h1>
                  <h3 className="py-2 text-sm">
                    Baggage discounts may apply based on frequent flyer
                    status/online checkin/form of payment/military/etc.
                  </h3>
                  <div className="flex flex-row justify-between">
                    <div>
                      <h1 className="text-lg text-slate-800">
                        IMPORTANT INFORMATION FOR TRAVELERS WITH ELECTRONIC
                        TICKETS • PLEASE READ:{" "}
                      </h1>
                      <h4 className="tracking-wide text-slate-600">
                        Carriage and other services provided by the carrier are
                        subject to conditions of carriage, which are hereby
                        incorporated by reference. These conditions may be
                        obtained from the issuing carrier. Passengers on a
                        journey involving an ultimate destination or a stop in a
                        country other than the country of departure are advised
                        that international or its predecessor, the Warsaw
                        Convention, including its amendments (the Warsaw
                        Convention System), may apply to the treaties known as
                        the Montreal Convention, entire journey, including any
                        portion thereof within a country. For such passengers,
                        the applicable treaty, including special contracts of
                        carriage embodied in any applicable in tariffs, governs
                        and may limit the liability of the carrier. The carriage
                        of certain hazardous materials, like aerosols,
                        fireworks, and flammable liquids, aboard the aircraft is
                        forbidden. If you do not understand these restrictions,
                        further information may be obtained from your airline.
                        be processed in accordance with the applicable carrier's
                        privacy policy and, where your booking is made via a
                        reservation Data Protection Notice: Your personal data
                        will These are available at
                        http://www.latatravelcenter.com/privacy or from the
                        carrier or GDS directly. You should read this system
                        provider ("GDS"), with its privacy policy.
                        documentation, which applies to your booking and
                        specifies, for example, how your personal data is
                        collected, stored, used, disclosed and transferred.
                      </h4>
                    </div>
                  </div>
                </div>
              </>
            </div> */}
          </div>
        </div>
        <div className="flex gap-6 justify-center p-6 mt-10 space-x-6">
          <button
            className="p-3 text-black bg-white rounded-md transition duration-300 ease-in-out transform outline hover:bg-gray-100 hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
            onClick={handlePrint}
          >
            Download Ticket
          </button>
          <button
            className="p-3 text-black bg-white rounded-md transition duration-300 ease-in-out transform outline hover:bg-gray-100 hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
            onClick={handlePrint}
          >
            Print Ticket
          </button>
        </div>
      </>
    );
  }
);

function FlightDetail(fareInfo, segment, index) {
  return (
    <>
      <h1 className="py-1 font-bold text-md">
        Flight: {segment?.Origin} - {segment?.Destination} - Passenger (
        {++index || 1})
      </h1>
      <div className="flex flex-row justify-between px-4">
        <div>
          <h2 className="font-semibold">Baggage Allowance</h2>
          <div className="">
            <div className="space-y-1">
              {fareInfo?.BaggageAllowance?.NumberOfPieces ? (
                <h3>
                  <span className="font-semibold">
                    {fareInfo?.BaggageAllowance?.NumberOfPieces?._text}{" "}
                  </span>
                  Piece(s) Plan
                </h3>
              ) : (
                <h3>
                  <span className="font-semibold">
                    {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Value}{" "}
                  </span>
                  {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Unit}{" "}
                  Plan
                </h3>
              )}
            </div>
          </div>
        </div>
        <div>
          <h2 className="font-semibold">Fare Basis:</h2>
          <div className="">{fareInfo?._attributes?.FareBasis}</div>
        </div>
        <div>
          <h2 className="font-semibold">Not Valid Before:</h2>
          <h3>
            {moment(fareInfo?._attributes?.NotValidBefore).format(
              "MMM DD, YYYY"
            )}
          </h3>
        </div>
        <div>
          <h2 className="font-semibold">Not Valid After:</h2>
          <h3>
            {" "}
            {moment(fareInfo?._attributes?.NotValidAfter).format(
              "MMM DD, YYYY"
            )}
          </h3>
        </div>
      </div>
    </>
  );
}
