import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getBilling,
  createBilling,
  getTotalPayableCredit,
  createManualBilling,
  resetBilling,
} from "../../redux2/billing/billingSlice";
import {
  updateCreditLimit,
  updateServiceCharge,
} from "../../redux2/user/userSlice";
import excelExport from "../../utils/excelExport";
import Loading from "../../components/Loading";
import {
  ONE_WEEK_CREDIT,
  TWO_WEEK_CREDIT,
  USER_ROLE_FINANCE,
  USER_ROLE_SUPERAGENT,
} from "../../common/constants";

import { formatNumber } from "../../utils/convertToNumber";
import TransactionList from "./components/TransactionList";
import AddWalletModal from "./Modals/AddWalletModal";
import UpdateCreditLimitModal from "./Modals/UpdateCreditLimitModal";
import UpdateServiceChargeModal from "./Modals/UpdateServiceChargeModal";
import BillingActions from "./components/BillingActions";
import { toast } from "react-toastify";

const AgentBilling = () => {
  const [openAddWallet, setOpenAddWallet] = useState(false);
  const [openAddLimit, setOpenAddLimit] = useState(false);
  const [openServiceCharge, setOpenServiceCharge] = useState(false);
  const [openManualBilling, setOpenManualBilling] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const { id } = useParams();

  const { billing, credit, isLoading, errorMessage, isError } = useSelector(
    (state) => state.billing
  );
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage);
    }
    dispatch(resetBilling());
  }, [errorMessage, isError, dispatch]);

  useEffect(() => {
    if (![USER_ROLE_SUPERAGENT, USER_ROLE_FINANCE].includes(user?.role)) {
      window.location.href = "/booking";
    }
  }, [user?.role]);

  useEffect(() => {
    let data = {
      query: {
        startDate: "",
        endDate: "",
        limit: 20,
        page: currentPage || 1,
      },
      id,
    };
    dispatch(getBilling(data));
  }, [currentPage, dispatch, id]);

  useEffect(() => {
    if (
      billing?.company &&
      (billing?.company?.creditType === ONE_WEEK_CREDIT ||
        billing?.company?.creditType === TWO_WEEK_CREDIT)
    ) {
      dispatch(getTotalPayableCredit(id));
    }
  }, [billing?.company, dispatch, id]);

  function handleBilling(values) {
    setOpenAddWallet(false);
    const data = {
      ...values,
      id,
    };
    dispatch(createBilling(data));
    // window.location.reload();
  }

  function handleLimit(values) {
    setOpenAddLimit(false);

    const data = {
      ...values,
      id,
    };

    dispatch(updateCreditLimit(data));
    // window.location.reload();
  }

  function handleServiceCharge(values) {
    setOpenServiceCharge(false);

    const data = {
      ...values,
      id,
    };

    dispatch(updateServiceCharge(data));
  }

  function handleExport() {
    let name =
      billing?.company?.name + "Billing" + moment().format("DD-MM-YYYY");

    const data = billing?.billing?.map((bill) => {
      return {
        "Transaction ID": bill.transaction || 0,
        Amount: bill.amount || bill?.deposit,
        "Payment Method": bill.bank,
        "Service Charge": bill.serviceCharge || 0,
        "Paid By": bill.paidBy,
        "Transaction Date": moment(bill.createdAt).format("DD-MM-YYYY"),
      };
    });
    excelExport(data, name);
  }

  const [pricingRules, setPricingRules] = useState([
    { minPrice: 0, maxPrice: 34999, defaultCharge: 11 },
    { minPrice: 35000, maxPrice: 49999, defaultCharge: 15 },
    { minPrice: 50000, maxPrice: 59999, defaultCharge: 18 },
    { minPrice: 60000, maxPrice: 69999, defaultCharge: 22 },
    { minPrice: 70000, maxPrice: 79999, defaultCharge: 25 },
    { minPrice: 80000, maxPrice: 89999, defaultCharge: 29 },
    { minPrice: 90000, maxPrice: 99999, defaultCharge: 33 },
    { minPrice: 100000, maxPrice: 500000, defaultCharge: 36 },
    // { minPrice: 200000, maxPrice: 500000, defaultCharge: 54 },
  ]);

  // const [businessPricingRules] = useState([
  //   { minPrice: 20000, maxPrice: 34999, defaultCharge: 14 },
  //   { minPrice: 35000, maxPrice: 49999, defaultCharge: 18 },
  //   { minPrice: 50000, maxPrice: 69999, defaultCharge: 21 },
  //   { minPrice: 70000, maxPrice: 89999, defaultCharge: 25 },
  //   { minPrice: 90000, maxPrice: 109999, defaultCharge: 28 },
  //   { minPrice: 110000, maxPrice: 129999, defaultCharge: 32 },
  //   { minPrice: 130000, maxPrice: 149999, defaultCharge: 35 },
  //   { minPrice: 150000, maxPrice: 199999, defaultCharge: 48 },
  //   { minPrice: 200000, maxPrice: 500000, defaultCharge: 57 },
  // ]);

  const columnsCount = 2;

  const initialValues = billing?.company?.serviceChargeInternational?.[0]
    ?.charge
    ? billing?.company?.serviceChargeInternational?.reduce(
        (acc, rule, index) => ({
          ...acc,
          [`minPrice_${index}`]: rule.minPrice,
          [`maxPrice_${index}`]: rule.maxPrice,
          [`charge_${index}`]:
            rule.charge !== undefined
              ? rule.charge
              : pricingRules?.[index].defaultCharge,
        }),
        {
          local: billing?.company?.serviceChargeLocal ?? 0,
          international: billing?.company?.serviceChargeInternational ?? 0,
          internationalBusiness:
            billing?.company?.serviceChargeInternationalBusiness ?? 0,
          serviceChargeLabor: billing?.company?.serviceChargeLabor ?? 0,
          serviceChargeOneWay: billing?.company?.serviceChargeOneWay ?? 0,
          exchangeLocalSC: billing?.company?.exchangeLocalSC ?? 0,
          exchangeInternationalSC:
            billing?.company?.exchangeInternationalSC ?? 0,
          isLabor: billing?.company?.isLabor ?? false,
          isOneWay: billing?.company?.isOneWay ?? false,
          NDCServiceCharges: billing?.company?.NDCServiceCharge,
        }
      )
    : pricingRules.reduce(
        (acc, rule, index) => ({
          ...acc,
          [`minPrice_${index}`]: rule.minPrice,
          [`maxPrice_${index}`]: rule.maxPrice,
          [`charge_${index}`]:
            rule.charge !== undefined
              ? rule.charge
              : pricingRules?.[index].defaultCharge,
        }),
        {
          local: billing?.company?.serviceChargeLocal ?? 0,
          international: billing?.company?.serviceChargeInternational ?? 0,
          internationalBusiness:
            billing?.company?.serviceChargeInternationalBusiness ?? 0,
          serviceChargeLabor: billing?.company?.serviceChargeLabor ?? 0,
          serviceChargeOneWay: billing?.company?.serviceChargeOneWay ?? 0,
          isLabor: billing?.company?.isLabor ?? false,
          isOneWay: billing?.company?.isOneWay ?? false,
          NDCServiceCharges: billing?.company?.NDCServiceCharge ?? [
            { carrier: "EK", oneWay: 0, round: 0 },
            { carrier: "KQ", oneWay: 800, round: 1500 },
            { carrier: "QR", oneWay: 300, round: 1100 },
            { carrier: "TK", oneWay: 1000, round: 2000 },
          ],
        }
      );

  // const businessInitialValues =
  //   billing?.company?.serviceChargeInternationalBusiness.reduce(
  //     (acc, rule, index) => ({
  //       ...acc,
  //       [`businessMinPrice_${index}`]: rule.minPrice,
  //       [`businessMaxPrice_${index}`]: rule.maxPrice,
  //       [`businessCharge_${index}`]:
  //         rule.charge !== undefined
  //           ? rule.charge
  //           : businessPricingRules?.[index].defaultCharge,
  //     }),
  //     {}
  //   );

  // const combinedInitialValues = {
  //   ...initialValues,
  //   ...businessInitialValues,
  // };

  const [NDCServiceCharges, setNDCServiceCharges] = useState([
    { carrier: "EK", oneWay: 0, round: 0 },
    { carrier: "KQ", oneWay: 800, round: 1500 },
    { carrier: "QR", oneWay: 300, round: 1100 },
    { carrier: "TK", oneWay: 1000, round: 2000 },
  ]);

  useEffect(() => {
    if (billing?.company?.NDCServiceCharge?.length > 0) {
      setNDCServiceCharges(billing?.company?.NDCServiceCharge);
    }
  }, [billing?.company?.NDCServiceCharge]);

  const handleChangeCharge = (index, event) => {
    const { name, value } = event.target;

    const newServiceCharges = NDCServiceCharges.map((charge, i) =>
      i === index ? { ...charge, [name]: +value } : charge
    );
    setNDCServiceCharges(newServiceCharges);
  };

  function handleManualBilling(values) {
    setOpenManualBilling(false);

    const data = {
      ...values,
      companyId: id,
    };

    dispatch(createManualBilling(data));
  }

  if (isLoading) {
    return <Loading />;
  }

  const statData = [
    {
      title: "Wallet Balance",
      data: formatNumber(`${billing?.company?.wallet?.toFixed(2)}`) + " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-wallet2"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "primary",
    },
    {
      title: "Available Credit",
      data:
        formatNumber(`${billing?.company?.creditLimit?.toFixed(2) || 0}`) +
        " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Available USD Credit",
      data:
        formatNumber(`${billing?.company?.USDCreditLimit?.toFixed(2) || 0}`) +
        " USD",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Total Short-term Credit",
      data:
        formatNumber(`${billing?.company?.STCredit?.toFixed(2) || 0}`) +
        " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
  ];

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const isSuper = [USER_ROLE_SUPERAGENT].includes(user?.role);

  return (
    <div className="row lg:mx-24 md:mx-16">
      <h1 className="py-3 pl-2 text-3xl capitalize">
        {billing?.company?.name}
      </h1>
      <div className="row">
        {statData.map(({ icon, color, title, data }) => (
          <div className="col-xl-3 col-xxl-3 col-lg-6 col-sm-6" key={title}>
            <div className="widget-stat card">
              <div className="p-3 card-body">
                <div className="media ai-icon">
                  <span className={`me-3 bgl-${color} text-${color}`}>
                    {icon}
                  </span>
                  <div className="media-body">
                    <p className="mb-1">{title}</p>
                    <h4 className="mb-0">{data}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <BillingActions
        isSuper={isSuper}
        setOpenAddWallet={setOpenAddWallet}
        setOpenAddLimit={setOpenAddLimit}
        setOpenServiceCharge={setOpenServiceCharge}
        handleExport={handleExport}
        billing={billing}
        id={id}
        credit={credit}
        openManualBilling={openManualBilling}
        setOpenManualBilling={setOpenManualBilling}
        handleManualBilling={handleManualBilling}
      />

      <TransactionList
        billing={billing}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
      />

      <AddWalletModal
        openAddWallet={openAddWallet}
        setOpenAddWallet={setOpenAddWallet}
        handleBilling={handleBilling}
      />

      <UpdateCreditLimitModal
        openAddLimit={openAddLimit}
        setOpenAddLimit={setOpenAddLimit}
        billing={billing}
        handleLimit={handleLimit}
      />

      <UpdateServiceChargeModal
        openServiceCharge={openServiceCharge}
        setOpenServiceCharge={setOpenServiceCharge}
        handleServiceCharge={handleServiceCharge}
        initialValues={initialValues}
        pricingRules={pricingRules}
        columnsCount={columnsCount}
        NDCServiceCharges={NDCServiceCharges}
        handleChangeCharge={handleChangeCharge}
      />
    </div>
  );
};

export default AgentBilling;
