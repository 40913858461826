import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { MenuItem, TextField, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { shortTermCreditSettlement } from "../../../redux2/billing/billingSlice";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import { WALLET_MINIMUM_AMOUNT } from "../../../common/constants";
import { ShortTermCreditSettlementSchema } from "../user.util";

const FormField = ({
  name,
  label,
  type = "text",
  required = true,
  select = false,
  children,
  ...props
}) => (
  <div className="mb-4">
    <Field name={name}>
      {({ field, meta }) => (
        <TextField
          {...field}
          fullWidth
          id={name}
          label={label}
          type={type}
          size="small"
          variant="outlined"
          required={required}
          select={select}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          {...props}
        >
          {children}
        </TextField>
      )}
    </Field>
  </div>
);

const ShortTermCreditSettlement = ({ billing, id, credit }) => {
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();
  const { company } = billing;

  const handleCloseModal = () => setOpenModal(false);

  function handleCreditDeposit(values) {
    if (
      values.bank === "wallet" &&
      company.STCredit > billing?.company?.wallet - WALLET_MINIMUM_AMOUNT
    ) {
      toast.error("Insufficient Balance");
      return;
    }

    const data = {
      id,
      ...values,
    };

    dispatch(shortTermCreditSettlement(data));
  }

  const initialValues = {
    amount: 0,
    transaction: "",
    reason: "",
    bank: "",
  };

  return (
    <div>
      <div
        className="transition-colors cursor-pointer hover:text-main"
        onClick={() => setOpenModal(true)}
      >
        Short-term Credit
      </div>

      <Modal
        show={openModal}
        onHide={handleCloseModal}
        size="md"
        centered
        backdrop="static"
      >
        <div className="overflow-hidden rounded-2xl">
          <div className="flex justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
            <Typography variant="h5" fontWeight="600">
              Short-term Credit Settlement
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              size="small"
              sx={{ color: "error.main" }}
            >
              <CloseIcon />
            </IconButton>
          </div>

          <div className="p-6">
            <div className="p-4 mb-6 bg-gray-100 rounded">
              <Typography variant="subtitle1">
                Outstanding short-term credit amount:{" "}
                <Typography component="span" fontWeight="bold" color="primary">
                  {convertNumbThousand(company.STCredit)} birr
                </Typography>
              </Typography>
            </div>

            <Formik
              initialValues={initialValues}
              onSubmit={handleCreditDeposit}
              validationSchema={ShortTermCreditSettlementSchema}
            >
              {({ values, handleChange, handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <FormField
                    name="amount"
                    label="Amount"
                    type="number"
                    placeholder="Amount to settle"
                    inputProps={{
                      min: 1,
                      max: company.STCredit,
                    }}
                  />

                  <FormField name="bank" label="Bank" select>
                    <MenuItem value="cbe">CBE</MenuItem>
                    <MenuItem value="awash">Awash Bank</MenuItem>
                    <MenuItem value="bunna">Bunna Bank</MenuItem>
                    <MenuItem value="wegagen">Wegagen Bank</MenuItem>
                    <MenuItem value="abyssinia">Abyssinia Bank</MenuItem>
                    <MenuItem value="amhara">Amhara Bank</MenuItem>
                    <MenuItem value="dashen">Dashen Bank</MenuItem>
                    <MenuItem value="zemen">Zemen Bank</MenuItem>
                    <MenuItem value="oromia">Oromia Bank</MenuItem>
                    <MenuItem value="coop">Cooperative Bank of Oromia</MenuItem>
                    <MenuItem value="wallet">Wallet</MenuItem>
                  </FormField>

                  <FormField
                    name="transaction"
                    label="Transaction ID / Payment Reference"
                    required={values.bank !== "wallet"}
                    disabled={values.bank === "wallet"}
                  />

                  <FormField name="reason" label="Reason" />

                  <div className="flex justify-end mt-6">
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                      sx={{
                        px: 4,
                        bgcolor: "#EA9C2A",
                        "&:hover": { bgcolor: "#d18c24" },
                      }}
                      disabled={company.STCredit === 0}
                    >
                      Submit Payment
                    </LoadingButton>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShortTermCreditSettlement;
