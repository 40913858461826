import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  getNDCPassengerPrice,
  getNDCPrice,
  getPriceEach,
  getTotalNDCPrice,
} from "../helpers";
import { formatNumber } from "../../../utils/convertToNumber";
import FlightSegment from "./FlightSegment";
import { currencyType } from "../../../utils/flightInfo";
import NDCMiniFareRuleModal from "../modals/NDCMiniFareRule";
import BaggageModal from "../modals/BaggageModal";
import { mergeObjects } from "../utils";
import { WALLET_MINIMUM_AMOUNT } from "../../../common/constants";
// import pricing from "./fare.json";

const Pricing = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const q = new URLSearchParams(location.search);
  const adults = q.get("adults");
  const children = q.get("children");

  const [passengerIndex, setPassengerIndex] = useState(0);

  const {
    state: {
      data: { response, queryURL, oneWayLength, fees },
    },
  } = useLocation();
  const pricing = response;

  const {
    DataLists: {
      FlightSegmentList: { FlightSegment: segment },
      OriginDestinationList: { OriginDestination },
      AnonymousTravelerList: { AnonymousTraveler },
      CarryOnAllowanceList: { CarryOnAllowance } = {},
      CheckedBagAllowanceList: { CheckedBagAllowance } = {},
      PenaltyList: { Penalty } = {},
    },
    PricedFlightOffers: {
      PricedFlightOffer: {
        0: { OfferPrice },
      },
    },
  } = pricing;

  const price = OfferPrice?.[0]?.RequestedDate?.PriceDetail;

  const getPenalty = (ref) => Penalty.find((item) => item.ObjectKey === ref);

  const combinePenalties = (rules) => {
    const combinedPenalties = [];
    for (let i = 0; i < rules?.[0]?.penalty?.length; i++) {
      const combinedPenalty = [];
      for (let j = 0; j < rules.length; j++) {
        combinedPenalty.push(rules[j].penalty[i]);
      }
      combinedPenalties.push(combinedPenalty);
    }
    return combinedPenalties;
  };

  const getPenaltyDetails = (combinedPenalties) => {
    return combinedPenalties.map((item) => item.map(getPenalty));
  };

  const extractFlightRefs = (offerPrice, index) =>
    offerPrice?.RequestedDate?.Associations[
      index
    ]?.ApplicableFlight?.FlightSegmentReference?.map(
      (segment) => segment.ref
    ) || [];

  const calculateMinMax = (penaltyDetails) => {
    return penaltyDetails?.map((item) => {
      const amounts = item.flatMap((penalty) =>
        penalty?.Details.Detail[0]?.Amounts?.Amount?.flatMap(
          (amount) => amount?.CurrencyAmountValue?.value
        )
      );

      const filteredAmounts = amounts.filter((amount) => amount >= 0);
      const isAllowed = amounts.length === filteredAmounts.length;
      const type = item[0].Details.Detail[0].Type;
      const message =
        item[0].Details.Detail[0]?.Amounts?.Amount?.[0]?.ApplicableFeeRemarks
          .Remark[0].value;
      const hasMin =
        item[0].Details.Detail[0]?.Amounts?.Amount?.some((amount) =>
          amount?.AmountApplication?.includes("MIN")
        ) || 0;

      const min = filteredAmounts.length ? Math.min(...filteredAmounts) : 0;
      const max = filteredAmounts.length ? Math.max(...filteredAmounts) : 0;
      const code = item[0].Details.Detail[0].Application?.Code;

      return {
        min,
        max,
        isAllowed,
        item: item[0],
        type,
        message,
        hasMin,
        code,
      };
    });
  };

  const filterRulesByFlightRefs = (rules, flightRefs) =>
    rules.filter((item) => flightRefs.some((ref) => item.ref.includes(ref)));

  const processRules = (offerPrice, flightIndex) => {
    const flightRefs = extractFlightRefs(offerPrice, flightIndex);
    const rules = offerPrice.FareDetail.FareComponent.map((item) => ({
      penalty: item?.FareRules?.Penalty?.refs,
      ref: item.refs,
    }));

    const filteredRules = filterRulesByFlightRefs(rules, flightRefs);
    const combinedPenalties = combinePenalties(filteredRules);
    const penaltyDetails = getPenaltyDetails(combinedPenalties);
    // console.log(JSON.stringify(penaltyDetails, null, 2));
    return calculateMinMax(penaltyDetails);
  };

  const offerPrice = OfferPrice[passengerIndex];
  const departRes = processRules(offerPrice, 0);
  const returnRes = processRules(offerPrice, 1);

  const merge = mergeObjects(departRes, returnRes);

  let baseFare = 0;
  OfferPrice?.map((item) => {
    baseFare +=
      item.RequestedDate.PriceDetail?.TotalAmount.SimpleCurrencyPrice.value *
        item.RequestedDate.Associations[0].AssociatedTraveler.TravelerReferences
          .length || 1;
  });

  const travelerTypeCounts = { ADT: 0, CHD: 0, INF: 0, LBR: 0 };

  AnonymousTraveler.forEach((traveler) => {
    const type = traveler.PTC.value;
    if (travelerTypeCounts.hasOwnProperty(type)) {
      travelerTypeCounts[type]++;
    }
  });

  function getTravelerType(ref) {
    return AnonymousTraveler?.find((item) => item.ObjectKey === ref)?.PTC.value;
  }

  function handleClick() {
    if (Number(baseFare) < WALLET_MINIMUM_AMOUNT) return;
    navigate(`/ndc-booking${queryURL}`, { state: { pricing, fees } });
  }

  function getCarryOnBaggage(ref) {
    return CarryOnAllowance?.find((item) => item.ListKey === ref);
  }

  function getCheckedBaggage(ref) {
    return CheckedBagAllowance?.find((item) => item.ListKey === ref);
  }

  return (
    <div className="container mt-4 bg-white md:mt-8">
      <div className="row">
        <div className="col-md-9">
          <NDCMiniFareRuleModal
            segment={OriginDestination}
            penalty={{ 0: merge.A, 1: merge.B }}
            // penalty={{ 0: departRes, 1: returnRes }}
            offerPrice={OfferPrice}
            getTravelerType={getTravelerType}
            setPassengerIndex={setPassengerIndex}
          />
          <BaggageModal
            segment={OriginDestination}
            offerPrice={OfferPrice}
            getTravelerType={getTravelerType}
            setPassengerIndex={setPassengerIndex}
            passengerIndex={passengerIndex}
            getCarryOnBaggage={getCarryOnBaggage}
            getCheckedBaggage={getCheckedBaggage}
          />
          <FlightSegment
            segment={segment}
            offerPrice={OfferPrice}
            oneWayLength={oneWayLength}
            getCarryOnBaggage={getCarryOnBaggage}
            getCheckedBaggage={getCheckedBaggage}
            AnonymousTraveler={AnonymousTraveler}
          />
        </div>
        <div className="col-md-3 h-fit">
          <div className="shadow-lg card">
            <div className="p-3 text-center border-b border-solid border-x-gray-300">
              <div className="row">
                <div className="col-md-6">
                  <h3 className="card-title">Flight Cost</h3>
                </div>
                <div className="col-md-6">
                  <h3 className="card-title">
                    {price?.TotalAmount.SimpleCurrencyPrice.Code}{" "}
                    {/* {formatNumber(baseFare)} */}
                    {formatNumber(getNDCPrice(baseFare, fees?.NDC_SC))}
                  </h3>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="flex flex-col justify-between mx-3 space-y-1 text-black">
                <div className="flex justify-between pb-1 space-y-1">
                  <div className="">
                    <p>Base Fare </p>
                    <p>Taxes and Fees </p>
                  </div>
                  <div className="">
                    <p>
                      {price?.TotalAmount.SimpleCurrencyPrice.Code}{" "}
                      {OfferPrice?.reduce(
                        (acc, item) =>
                          acc +
                          item.RequestedDate.PriceDetail?.BaseAmount.value *
                            item.RequestedDate.Associations[0]
                              .AssociatedTraveler.TravelerReferences.length,
                        0
                      ) + (fees?.NDC_SC || 0)}{" "}
                    </p>
                    <p>
                      {price?.Taxes?.Total.Code}{" "}
                      {OfferPrice?.reduce(
                        (acc, item) =>
                          acc +
                          item.RequestedDate.PriceDetail?.Taxes.Total.value *
                            item.RequestedDate.Associations[0]
                              .AssociatedTraveler.TravelerReferences.length,
                        0
                      )}{" "}
                    </p>
                  </div>
                </div>
                <hr />

                {getPriceEach(OfferPrice)?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="flex justify-between items-center py-2"
                    >
                      <p>
                        {item.count} * {getTravelerType(item.type)}
                      </p>
                      <p className="">
                        {item.currency}{" "}
                        {formatNumber(
                          getNDCPassengerPrice(
                            item,
                            adults,
                            children,
                            fees,
                            getTravelerType(item.type)
                          )
                        )}
                      </p>
                    </div>
                  );
                })}

                <hr />
                <div className="flex justify-between mt-2 space-y-2">
                  <p>Service Charge </p>
                  <p>ETB {fees?.serviceCharge}</p>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <div className="justify-end d-flex">
                <div className="text-xl font-bold text-black">
                  Total Price: {price?.TotalAmount.SimpleCurrencyPrice.Code}{" "}
                  {/* {formatNumber(baseFare)} */}
                  {formatNumber(
                    getTotalNDCPrice(
                      baseFare,
                      fees?.serviceCharge,
                      fees?.NDC_SC
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 space-y-8 col-md-9">
          <div className="justify-end d-flex">
            <button
              className="rounded-lg btn btn-primary"
              onClick={handleClick}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
