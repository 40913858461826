import axios from "axios";

import API_URL from "..";
import { API } from "../client";

const API_URL_AGENT = API_URL + "agent/";

const getMembers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL_AGENT + "", config);

  return res.data;
};

const getMember = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL_AGENT + `${id}`, config);

  return res.data;
};

const createMember = async (token, member) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL_AGENT + "", member, config);

  return res.data;
};

const apiAgent = {
  getMembers,
  getMember,
  createMember,
};

export default apiAgent;
