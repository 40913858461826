import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Search } from "@mui/icons-material";
import Select from "react-select/";
import moment from "moment";

import {
  USER_ROLE_AGENT,
  USER_ROLE_EMPLOYEE,
  USER_ROLE_FINANCE,
  USER_ROLE_MEMBER,
  USER_ROLE_SUPERAGENT,
} from "../../../common/constants";
import BookingList from "./BookingList";
import excelExport from "../../../utils/excelExport";
import { useDispatch, useSelector } from "react-redux";
import {
  getBookingByUser,
  getBookings,
  getCompanyBookings,
} from "../../../redux2/booking/bookingSlice";
import Loading from "../../../components/Loading";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import StatCard from "../../Billing/StatCard";
import { getCompanyById } from "../../../redux2/user/userSlice";
import { getBookingReport } from "../../../services/report";
import { useSearchParams } from "react-router-dom";

const BookingStat = ({ user, query }) => {
  const { bookings: bookingsData, isLoading } = useSelector(
    (state) => state.booking
  );
  const { company, isLoading: loading } = useSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams();

  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [status, setStatus] = useState("");
  const [state, setState] = useState("");
  const [payment, setPayment] = useState("");
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [bookingPerPage, setBookingPerPage] = useState(
    parseInt(searchParams.get("limit")) || 5
  );
  const [channel, setChannel] = useState(
    parseInt(searchParams.get("channel")) || ""
  );

  const dispatch = useDispatch();

  query.page = currentPage || 1;

  const isSuper = [USER_ROLE_SUPERAGENT, USER_ROLE_EMPLOYEE].includes(
    user?.role
  );

  useEffect(() => {
    query.status = status.toLowerCase() || "";
    query.search = search || "";
    query.state = state || "";
    query.payment = payment || "";
    query.channel = channel || "";
    query.limit = bookingPerPage || 5;
    if (query.status) {
      query.state = "";
    }

    const fetchBookings = () => {
      switch (user?.role) {
        case USER_ROLE_SUPERAGENT:
        case USER_ROLE_EMPLOYEE:
        case USER_ROLE_FINANCE:
          dispatch(getBookings(query));
          break;
        case USER_ROLE_AGENT:
          dispatch(getCompanyBookings(query));
          break;
        default:
          dispatch(getBookingByUser(query));
          break;
      }
    };

    // wait search til 1 sec after user stop typing
    if (search) {
      const timeOutId = setTimeout(() => fetchBookings(), 1000);
      return () => clearTimeout(timeOutId);
    } else {
      fetchBookings();
    }
  }, [
    query,
    dispatch,
    user,
    currentPage,
    status,
    search,
    state,
    payment,
    bookingPerPage,
    channel,
  ]);

  useEffect(() => {
    dispatch(getCompanyById(user?.company));
  }, [dispatch, user]);

  useEffect(() => {
    if (bookingsData?.data) {
      setBookings(bookingsData?.data);
    }
  }, [bookingsData, setBookings]);

  function filterBookings(status) {
    let filteredData;
    if (status === "All") {
      filteredData = bookingsData;
    } else if (status === "Pending") {
      filteredData = {
        ...bookingsData,
        data: bookingsData?.data?.filter(
          (booking) => booking.status === "pending"
        ),
      };
    } else if (status === "Confirmed") {
      filteredData = {
        ...bookingsData,
        data: bookingsData?.data?.filter(
          (booking) => booking.status === "confirmed"
        ),
      };
    } else if (status === "Canceled") {
      filteredData = {
        ...bookingsData,
        data: bookingsData?.data?.filter(
          (booking) => booking.status === "cancelled"
        ),
      };
    }
    setBookings(filteredData);
  }

  function handleChannelChange(e) {
    setChannel(e.value);
    searchParams.set("channel", e.value);
    setSearchParams(searchParams);
  }

  const statData = [
    {
      title: "Wallet Balance",
      data:
        convertNumbThousand(
          company?.wallet || bookings?.[0]?.company?.wallet || 0
        ) + " Birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-wallet2"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "primary",
    },

    {
      title: "Available Credit",
      data:
        convertNumbThousand(
          company?.creditLimit || bookings?.[0]?.company?.creditLimit
        ) + " Birr",
      // data: "200,000 birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
  ];

  async function handleExport() {
    setLoader(true);

    let report = await getBookingReport(user?.token, query);

    function getRoute(data) {
      return data?.flight?.routes
        ?.map((route) =>
          route?.flights
            .map((flight, index) => {
              const separator = index === route?.flights.length - 1 ? "" : "-";
              return flight + separator;
            })
            .join("")
        )
        .join("-");
    }

    function mergeRoutes2(flights) {
      let routes = flights?.split("-");
      const uniqueFlights = routes?.filter(
        (flight, index) => flight !== routes[index - 1]
      );

      return uniqueFlights?.join("-");
    }

    let name = "Bookings " + moment().format("MMM DD, YYYY");
    const data = report?.bookings?.map((booking, index) => {
      return {
        "S/N": index + 1,
        "Issuing Date": moment(booking.createdAt).format("MMM DD, YYYY"),
        Status: booking.status,
        "Passenger Name": booking?.passengers
          ?.map((passenger) => passenger?.firstName + " " + passenger?.lastName)
          .join(" - "),
        // "Passenger Name":
        //   booking?.passengers?.firstName + " " + booking?.passengers?.lastName,
        "Ticket NO.": booking?.flight?.ticketNumber?.join(" - "),
        "Passenger Passport No.":
          booking?.passengers
            ?.map((passenger) => passenger?.passportNumber)
            ?.join(" - ") || "",
        "Issuing Airline": booking?.flight?.carrier,
        Nationality: booking?.passengers
          ?.map((passenger) => passenger?.nationality || "Ethiopian")
          .join(", "),
        Sector: mergeRoutes2(getRoute(booking)),
        "System Fare":
          booking?.status !== "voided" || booking?.status !== "void-late"
            ? booking?.flight?.price + (booking?.NDCServiceCharge || 0)
            : 0,
        "Sterling SC": booking?.serviceCharge,
        "Agent Fee":
          booking?.status !== "voided" ? booking?.commission || 0 : 0,
        Total: booking?.totalPrice,
        "Fare Difference": booking?.exchangeDifference || "",
        "Penalty + Tax": booking.penalty,
        Refunded: (booking.penalty || 0) + (booking.tax || 0),
        Payment: booking?.paymentMethod,
        "Issuing Company": booking?.company?.name || "",
        "Issued By":
          booking?.agent?.firstName + " " + booking?.agent?.lastName || "",
        // Remark: "",
      };
    });

    let result = report?.result?.[0];

    const totalPriceRow = {
      "S/N": "",
      "Issuing Date": "",
      Status: "",
      "Passenger Name": "",
      "Ticket NO.": "",
      "Passenger Passport No.": "",
      "Issuing Airline": "",
      Nationality: "",
      Sector: "Grand Total",
      "System Fare": (result?.price || 0) + " Birr",
      "Sterling SC": (result?.serviceCharge || 0) + " Birr",
      "Agent Fee": (result?.commission || 0) + " Birr",
      Total: (result?.totalAmount || 0) + " Birr",
      "Fare Difference": "",
      "Penalty + Tax": "",
      Refunded: "",
      Payment: "",
      "Issuing Company": "",
      "Issued By": "",
      // Remark: "",
    };

    const updatedData = [...data, totalPriceRow];

    const order = [
      "S/N",
      "Status",
      "Issuing Date",
      "Passenger Name",
      "Ticket NO.",
      "Passenger Passport No.",
      "Issuing Airline",
      "Nationality",
      "Sector",
      "System Fare",
      "Sterling SC",
      "Agent Fee",
      "Total",
      "Fare Difference",
      "Penalty + Tax",
      "Refunded",
      "Payment",
      "Issuing Company",
      "Issued By",
      // "Remark",
    ];

    excelExport(updatedData, name, order);
    setLoader(false);
  }

  if (isLoading || loading || loader) return <Loading />;

  return (
    <>
      {user?.role === USER_ROLE_MEMBER &&
        statData.map(({ icon, color, title, data }, index) => (
          <StatCard
            key={index}
            icon={icon}
            color={color}
            title={title}
            data={data}
          />
        ))}
      <div className="grid-cols-12">
        <div className="flex flex-wrap mb-4 justify-content-between align-items-center">
          <div className="mt-3 mb-4 card-tabs mt-sm-0 mb-xxl-0">
            <Nav as="ul" className="nav nav-tabs">
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="All"
                  onClick={() => {
                    filterBookings("All");
                    setStatus("");
                  }}
                >
                  All Booking ({bookingsData?.count?.total || 0})
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="Pending"
                  onClick={() => {
                    filterBookings("Pending");
                    setStatus("Pending");
                  }}
                >
                  Pending{" "}
                  {!isLoading && "(" + bookingsData?.count?.pending + ")"}
                  {/* {!isLoading && "(" + countBookingLength("Pending") + ")"} */}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="Ticketed"
                  onClick={() => {
                    filterBookings("Confirmed");
                    setStatus("Confirmed,voided,exchanged,refunded");
                  }}
                >
                  Ticketed{" "}
                  {!isLoading && "(" + bookingsData?.count?.confirmed + ")"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="Canceled"
                  onClick={() => {
                    filterBookings("Canceled");
                    setStatus("Cancelled");
                  }}
                >
                  Canceled{" "}
                  {!isLoading && "(" + bookingsData?.count?.cancelled + ")"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="Partial"
                  onClick={() => {
                    filterBookings("Partial");
                    setState("partial");
                    setStatus("");
                  }}
                >
                  Partial{" "}
                  {!isLoading &&
                    "(" + (bookingsData?.count?.partial || 0) + ")"}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item as="li" className="nav-item">
                <Nav.Link
                  className="nav-link"
                  eventKey="USDCredit"
                  onClick={() => {
                    filterBookings("USDCredit");
                    setPayment("USDCredit");
                    setStatus("");
                  }}
                >
                  USD Bookings
                  {!isLoading &&
                    "(" + (bookingsData?.count?.payment || 0) + ")"}
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="table-search">
            <div className="mb-4 input-group search-area mb-xxl-0">
              <input
                type="text"
                className="form-control"
                placeholder="Search here"
                // onChange={handleSearch}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className="input-group-text">
                <button>
                  <Search size={35} className="text-main" />
                  {/* <i className="flaticon-381-search-2 text-main"></i> */}
                </button>
              </span>
            </div>
          </div>
          <div className="flex justify-between mb-4 w-full">
            {user?.role !== USER_ROLE_EMPLOYEE &&
              user?.role !== USER_ROLE_MEMBER && (
                <button
                  className="text-white btn bg-main hover:bg-main-800 border-main-100 mb-xxl-0"
                  onClick={handleExport}
                >
                  <i className="far fa-file-word me-2"></i>Generate Report
                </button>
              )}

            {isSuper && (
              <div className="px-2 col-xl-4 col-4 col-xxl-2 mt-sm-0 mb-xxl-0">
                <Select
                  placeholder={searchParams.get("channel") ?? "Select Channel"}
                  value={searchParams.get("channel")}
                  options={[
                    { value: "", label: "All", default: true },
                    { value: "GDS", label: "GDS" },
                    { value: "NDC", label: "NDC" },
                  ]}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                  onChange={handleChannelChange}
                />
              </div>
            )}
          </div>
        </div>
        <Tab.Content>
          <Tab.Pane eventKey="All">
            <BookingList
              user={user}
              bookings={bookings?.data || bookings}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={bookingsData?.totalPages}
              bookingPerPage={bookingPerPage}
              setBookingPerPage={setBookingPerPage}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Pending">
            <BookingList
              user={user}
              bookings={bookings?.data || bookings}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={bookingsData?.totalPages}
              bookingPerPage={bookingPerPage}
              setBookingPerPage={setBookingPerPage}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Ticketed">
            <BookingList
              user={user}
              bookings={bookings?.data || bookings}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={bookingsData?.totalPages}
              bookingPerPage={bookingPerPage}
              setBookingPerPage={setBookingPerPage}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Canceled">
            <BookingList
              user={user}
              bookings={bookings?.data || bookings}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={bookingsData?.totalPages}
              bookingPerPage={bookingPerPage}
              setBookingPerPage={setBookingPerPage}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="Partial">
            <BookingList
              user={user}
              bookings={bookings?.data || bookings}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={bookingsData?.totalPages}
              bookingPerPage={bookingPerPage}
              setBookingPerPage={setBookingPerPage}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="USDCredit">
            <BookingList
              user={user}
              bookings={bookings?.data || bookings}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPage={bookingsData?.totalPages}
              bookingPerPage={bookingPerPage}
              setBookingPerPage={setBookingPerPage}
            />
          </Tab.Pane>
        </Tab.Content>
      </div>
    </>
  );
};

export default BookingStat;
