import { LoadingButton } from "@mui/lab";
import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import {
  ONE_DAY_CREDIT,
  WALLET_MINIMUM_AMOUNT,
} from "../../../common/constants";
import { createBookingDeposit } from "../../../redux2/billing/billingSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const CreditSettlement = ({ billing, id, credit }) => {
  const [openModal, setOpenModal] = useState(false);

  const dispatch = useDispatch();

  const { company } = billing;

  function handleCreditDeposit(values) {
    setOpenModal(false);

    if (
      values.bank === "wallet" &&
      creditBalance() > billing?.company?.wallet - WALLET_MINIMUM_AMOUNT
    ) {
      toast.error("Insufficient Balance");
      return;
    }

    const data = {
      id,
      totalAmount: creditBalance(),
      ...values,
    };

    dispatch(createBookingDeposit(data));
  }

  function creditBalance() {
    if (company?.creditType === ONE_DAY_CREDIT) {
      return company?.totalCreditLimit - company?.creditLimit;
    }
    return credit?.totalPayableCredit;
  }

  return (
    <div className="">
      <div className="cursor-pointer" onClick={() => setOpenModal(true)}>
        Credit Settlement
      </div>
      <Modal
        className="modal fade"
        show={openModal}
        onHide={setOpenModal}
        size="md"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Manual Credit Payment</h4>

              <button
                type="button"
                className="px-2 text-3xl font-bold text-red-500 rounded-full border-none shadow- xl hover:outline-none hover:ring-2"
                onClick={() => setOpenModal(false)}
              >
                &times;
              </button>
            </div>

            <div className="modal-body">
              {company?.creditType === "oneDay" ? (
                <>
                  <h4 className="mb-2 fs-18">
                    Outstanding credit payment amount:{" "}
                    <b className="">{convertNumbThousand(creditBalance())}</b>{" "}
                    birr
                  </h4>
                </>
              ) : (
                <>
                  <h4 className="mb-2 fs-18">
                    Total Payable Credit:{" "}
                    <b className="">
                      {convertNumbThousand(credit?.totalPayableCredit)}
                    </b>{" "}
                    birr
                  </h4>
                  <h4 className="mb-2 fs-16">
                    Facilitation Fee:{" "}
                    <b className="">
                      {convertNumbThousand(credit?.totalFacilitationFee)}
                    </b>{" "}
                    birr
                  </h4>
                  <h4 className="mb-2 fs-16">
                    Daily Fee:{" "}
                    <b className="">
                      {convertNumbThousand(credit?.totalDailyFee)}
                    </b>{" "}
                    birr
                  </h4>
                  <h4 className="mb-4 fs-16">
                    Penalty Fee:{" "}
                    <b className="">
                      {convertNumbThousand(credit?.totalPenaltyFee)}
                    </b>{" "}
                    birr
                  </h4>
                </>
              )}
              <i className="text-black flat-icon-cancel-12 close"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={{
                    transaction: "",
                    reason: "",
                    bank: "",
                  }}
                  onSubmit={handleCreditDeposit}
                  // validationSchema={EmployeeSchema}
                >
                  {({ values, handleChange, handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit} className="">
                      <div className="flex flex-col justify-center">
                        <div className="row">
                          <div className="mb-3">
                            <TextField
                              fullWidth
                              id="bank"
                              name="bank"
                              size="small"
                              value={values.bank}
                              label="Bank"
                              onChange={handleChange}
                              select
                              required
                              // error={touched.bank && Boolean(errors.bank)}
                              // helperText={touched.bank && errors.bank}
                            >
                              <MenuItem value="cbe">CBE</MenuItem>
                              <MenuItem value="awash">Awash Bank</MenuItem>
                              <MenuItem value="bunna">Bunna Bank</MenuItem>
                              <MenuItem value="wegagen">Wegagen Bank</MenuItem>
                              <MenuItem value="abyssinia">
                                Abyssinia Bank
                              </MenuItem>
                              <MenuItem value="amhara">Amhara Bank</MenuItem>
                              <MenuItem value="dashen">Dashen Bank</MenuItem>
                              <MenuItem value="zemen">Zemen Bank</MenuItem>
                              <MenuItem value="oromia">Oromia Bank</MenuItem>
                              <MenuItem value="coop">
                                Cooperative Bank of Oromia
                              </MenuItem>
                              <MenuItem value="wallet">Wallet</MenuItem>
                              <MenuItem value="short-term-credit">
                                Short-term Credit
                              </MenuItem>
                            </TextField>
                          </div>
                          <div className="mb-3">
                            <TextField
                              fullWidth
                              id="transaction"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="transaction"
                              placeholder="Transaction ID/ Payment Reference"
                              label="Transaction ID/ Payment Reference"
                              disabled={values.bank === "short-term-credit"}
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.transaction}
                              onChange={handleChange}
                              required={
                                values.bank !== "wallet" &&
                                values.bank !== "short-term-credit"
                              }
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>

                          <div className="mb-8">
                            <TextField
                              fullWidth
                              id="reason"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="reason"
                              placeholder="Reason"
                              label="Reason"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.reason}
                              onChange={handleChange}
                              required
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"

                          // loading={add?.loading}
                        >
                          Credit Paid
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreditSettlement;
