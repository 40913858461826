import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import moment from "moment";
import { getCarrierLogo } from "../../utils/flightInfo";
import calculateTime from "../../utils/calculateTime";
import customTime from "../../utils/customTime";

const StopFlightDetails = ({
  flightInfo,
  flightDetails,
  fareRule,
  index,
  isRoundTrip,
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => setShowModal((prev) => !prev);
  const [isOpen, setIsOpen] = useState(!isRoundTrip);

  // on round trip use key for baggage info

  const renderDetailTop = (seg) => {
    return (
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-shrink-0 mt-10 w-24 md:w-20 lg:w-24 md:pt-7">
            <img
              src={getCarrierLogo(seg._attributes?.Carrier)}
              className="w-15"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0 lg:w-36">
            <div className="flex flex-col flex-shrink-0 items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow my-1 border-l border-dashed border-neutral-400"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {customTime(seg._attributes?.DepartureTime)}
                </span>
                <span className="font-semibold text-black">
                  {seg?._attributes?.Origin}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {customTime(seg?._attributes?.ArrivalTime)}
                </span>
                <span className="font-semibold text-black">
                  {seg?._attributes?.Destination}
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="space-y-1 text-sm text-neutral-500 dark:text-neutral-400 md:space-y-2">
            {/* <li>
              Trip time:{" "}
              <span>
                {calculateTime(
                  flightInfo?.ArrivalTime,
                  flightInfo?.DepartureTime
                )}
              </span>
            </li> */}
            {/* <li>
              Flight Class:{" "}
              {isRoundTrip
                ? flightDetails?.BookingInfo?.[index]?._attributes?.CabinClass
                : flightDetails?.BookingInfo?._attributes?.CabinClass}
            </li> */}
            <li>
              <h2 className="text-md">Baggage Allowance / No baggage fee</h2>
              <div className="px-4 py-2 row">
                <div className="col-md-8">
                  <p>AirLine</p>
                  <p>Carry on</p>
                  <p>Baggage Units</p>
                  <p>No baggage fee upto</p>
                  <p>From - To / Airline</p>
                </div>
                <div className="col-md-4">
                  <p>
                    {flightInfo?.Carrier ||
                      flightInfo?.[0]?._attributes?.Carrier}
                  </p>
                  <p>
                    {isRoundTrip
                      ? flightDetails?.BaggageAllowances
                          ?.CarryOnAllowanceInfo?.[index]?.TextInfo?.Text?._text
                      : flightDetails?.BaggageAllowances
                          ?.CarryOnAllowanceInfo?.[index]?.TextInfo?.Text
                          ?._text}
                  </p>
                  <p>
                    {isRoundTrip
                      ? flightDetails?.BaggageAllowances
                          ?.BaggageAllowanceInfo?.[index]?.TextInfo?.Text?.[0]
                          ?._text
                      : flightDetails?.BaggageAllowances?.BaggageAllowanceInfo
                          ?.TextInfo?.Text?.[0]?._text}
                  </p>
                  <p>
                    {flightDetails?.BaggageAllowances?.BaggageAllowanceInfo?.[0]
                      ?.BagDetails?.[1]?.BaggageRestriction?.TextInfo?.Text
                      ?._text ||
                      flightDetails?.BaggageAllowances?.BaggageAllowanceInfo
                        ?.BagDetails?.[1]?.BaggageRestriction?.TextInfo?.Text
                        ?._text}
                  </p>
                  {seg?._attributes?.Origin} - {seg?._attributes?.Destination}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 mx-6 border border-gray-900 shadow-lg md:p-8">
        {flightInfo?.map((seg, index) => (
          <>
            {renderDetailTop(seg)}
            {index === flightInfo.length - 1 ? null : (
              <div className="my-7 space-y-5 md:my-10 md:pl-24">
                <div className="border-t border-neutral-200 dark:border-neutral-700" />
                <div className="text-sm text-neutral-700 dark:text-neutral-300 md:text-base">
                  Transit time:{" "}
                  {calculateTime(
                    flightInfo?.[flightInfo.length - 1]?._attributes
                      ?.DepartureTime,
                    flightInfo?.[0]?._attributes?.ArrivalTime
                  )}
                </div>
                <div className="border-t border-neutral-200 dark:border-neutral-700" />
              </div>
            )}
          </>
        ))}
      </div>
    );
  };

  return (
    <>
      <div className="col-md-12">
        <div className="shadow-lg card">
          <div className="row">
            <div className="card-header">
              <div className="mx-2 col-md-6">
                <h3 className="card-title">Flight Detail</h3>
              </div>
              <div className="col-md-6">
                <div className="float-right mx-2">
                  <h3 className="card-subtitle">
                    {flightInfo?.[0]?._attributes?.Group === "0"
                      ? "Depart Flight"
                      : "Return Flight"}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body" data-nc-id="FlightCard">
            <div className={`relative  sm:pr-20`} data-nc-id="FlightCard">
              <div className="row">
                <div className="col-md-2">
                  <div className="flex items-center space-x-2">
                    <div className="text-xl text-black">
                      <h1>
                        {moment(
                          flightInfo?.[0]?._attributes?.DepartureTime
                        ).format("MMMM Do")}
                      </h1>
                      <h1>
                        {customTime(
                          flightInfo?.[0]?._attributes?.DepartureTime
                        )}
                      </h1>
                      <h1 className="p-2 text-lg font-light">
                        {flightInfo?.[0]?._attributes?.Origin}
                      </h1>
                    </div>
                  </div>
                </div>
                {/* {"->"} */}
                <div className="col-md-1">
                  <span className="flex justify-center w-12">
                    <i className="text-2xl  las la-long-arrow-alt-right"></i>
                  </span>
                </div>
                <div className="col-md-2">
                  <div className="flex items-center space-x-2">
                    <div className="text-xl text-black">
                      <h1>
                        {moment(
                          flightInfo?.[flightInfo.length - 1]?._attributes
                            ?.ArrivalTime
                        ).format("MMMM Do")}
                      </h1>
                      <h1>
                        {customTime(
                          flightInfo?.[flightInfo.length - 1]?._attributes
                            ?.ArrivalTime
                        )}
                      </h1>
                      <h1 className="p-2 text-lg font-light">
                        {
                          flightInfo?.[flightInfo.length - 1]?._attributes
                            ?.Destination
                        }{" "}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="flex items-center px-4 py-3 space-x-2">
                    <div className="text-lg text-black">
                      <h1 className="text-sm text-neutral-500 font-normal mt-0.5">
                        {moment(
                          flightInfo?.[flightInfo.length - 1]?._attributes
                            ?.ArrivalTime
                        ).diff(
                          flightInfo?.[0]?._attributes?.DepartureTime,
                          "hours"
                        )}{" "}
                        Hours{" "}
                        {moment(
                          flightInfo?.[flightInfo.length - 1]?._attributes
                            ?.ArrivalTime
                        ).diff(
                          flightInfo?.[0]?._attributes?.DepartureTime,
                          "minutes"
                        ) % 60}
                        Minutes
                      </h1>
                      <h1>
                        {flightInfo?.length - 1 <= 0
                          ? "Direct"
                          : flightInfo?.length - 1 + " Stops"}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="space-y-6 col-md-3">
                  <div className="flex justify-between py-2 space-x-2">
                    <div className="text-sm text-neutral-500 font-normal mt-0.5 text-light">
                      <h1 className="pb-2 space-y-6">Flight Equipment</h1>
                      <h1 className="pb-2">Flight Number</h1>
                      <h1 className="pb-2">Flight Class</h1>
                    </div>
                    <div className="">
                      <h1 className="pb-2">
                        {flightInfo?.map((flight, index) => {
                          return (
                            <>
                              <span>{flight._attributes?.Equipment}, </span>
                            </>
                          );
                        })}
                      </h1>
                      <h1 className="pb-2">
                        {flightInfo?.map((flight, index) => {
                          return (
                            <>
                              <span>{flight._attributes?.FlightNumber}, </span>
                            </>
                          );
                        })}
                      </h1>
                      <h1 className="pb-2">
                        {isRoundTrip
                          ? flightDetails?.BookingInfo?.[index]?._attributes
                              ?.CabinClass
                          : // : flightDetails?.BookingInfo?.map(
                            //     (booking, index) => {
                            //       return (
                            //         <>
                            //           <span>
                            //             {booking?._attributes?.CabinClass},{" "}
                            //           </span>
                            //         </>
                            //       );
                            flightDetails?.BookingInfo?.[0]?._attributes
                              ?.CabinClass ??
                            flightDetails?.[index]?.BookingInfo?.[0]
                              ?._attributes?.CabinClass}{" "}
                        {/* remove index */}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="flex items-center py-2 space-x-2">
                    <div className="flex-shrink-0 py-2 w-24 lg:w-32 md:ml-10">
                      <img
                        src={getCarrierLogo(
                          flightInfo?.[0]?._attributes?.Carrier
                        )}
                        className="w-16"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-lg font-medium text-black">
              <span
                className={`absolute right-5 bottom-0 sm:bottom-auto sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
                  isOpen ? "transform -rotate-180" : ""
                }`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <i className="text-xl las la-angle-down"></i>
              </span>
            </div>
          </div>
          <div className="col-md-3">
            <button
              onClick={openModal}
              className="px-4 py-2 text-base font-bold text-main"
            >
              Show Fare Rule
            </button>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {/* </div> */}
      {/* </div> */}
      <div className="row">
        <div className="mt-1 mb-10 col-md-12">{renderDetail()}</div>
      </div>
      <Modal
        show={showModal}
        onHide={openModal}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        cancelButton={true}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Fare Rule</h5>
            <button
              type="button"
              className="btn-close"
              onClick={openModal}
              data-dismiss="modal"
            >
              <i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M15.854 1.646a.5.5 0 0 1 0 .708L1.707 15.854a.5.5 0 0 1-.708-.708L15.146 1.646a.5.5 0 0 1 .708 0z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M1.646 1.646a.5.5 0 0 0 0 .708L15.793 15.854a.5.5 0 0 0 .708-.708L2.354 1.646a.5.5 0 0 0-.708 0z"
                  />
                </svg>
              </i>
            </button>
          </div>
          <div className="modal-body">
            {fareRule?.map((item, index) => {
              return (
                <div key={index}>
                  <p>
                    {item._attributes.Category}, - {item._text}
                  </p>
                  <br />
                </div>
              );
            })}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn"
              data-bs-dismiss="modal"
              onClick={openModal}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default StopFlightDetails;
