import axios from "axios";

import API_URL from "..";
import { API } from "../client";

// const API_URL_USER = API_URL + "user/";

// const API_URL = "http://localhost:5000/api/v1/";
// const API_URL = "https://airflight-api.onrender.com/api/v1/";

/**
 * Auth
 */

// Register User
const register = async (userData) => {
  const res = await axios.post(API_URL + "auth/signup", userData);

  if (res.data) {
    localStorage.setItem("user", JSON.stringify(res.data.user));
  }

  return res.data;
};

// Login User
const login = async (userData) => {
  const res = await axios.post(API_URL + "auth/login", userData);

  if (res.data) {
    const twoHours = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
    // const fiveHours = 5 * 60 * 60 * 1000; // 5 hours in milliseconds
    const expiresAt = new Date().getTime() + twoHours;
    localStorage.setItem("user", JSON.stringify(res.data.user));
    localStorage.setItem("expirationTime", expiresAt);
  }

  return res.data;
};

// Get Me
const getMe = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await API.get(API_URL + "auth/me", config);

  return res.data;
};

// Update Password
const updatePassword = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const res = await axios.put(
    API_URL + "auth/update-password",
    userData,
    config
  );

  return res.data;
};

// Forget Password
const forgetPassword = async (userData, token) => {
  const res = await axios.post(API_URL + "auth/forget-password", userData);

  return res.data;
};

// Reset Password
const resetPassword = async (userData) => {
  const res = await axios.put(API_URL + "auth/reset-password", userData);

  return res.data;
};

// Logout User
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("expirationTime");
  // window.location.reload();
};

/**
 * User
 */

// user api
/**
 * User
 * @param {*} token
 * create user
 */

const createUser = async (token, user) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL + "user", user, config);

  return res.data;
};

const getUser = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + "auth/user", config);

  return res.data;
};

const getAllUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + "admin/users", config);

  return res.data;
};

const updateUser = async (token, user) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(API_URL + `user/${user.id}`, user, config);

  return res.data;
};

const userExists = async (token, phone) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + `user/exist/${phone}`, config);

  return res.data;
};

const getCompany = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + `company/${id}`, config);

  return res.data;
};

const getCompanyById = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + `company/getcompany/${id}`, config);

  return res.data;
};

const getCompanies = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + "company/", config);

  return res.data;
};

const getMyCompany = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + "company/me", config);

  return res.data;
};

const createCompany = async (token, company) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL + "company/", company, config);

  return res.data;
};

const updateCompany = async (token, company) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + `company/${company.id}`,
    company,
    config
  );

  return res.data;
};

const updateCompanyStatus = async (token, { id, status }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + `company/status/${id}`,
    { status },
    config
  );

  return res.data;
};

const updateCreditLimit = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + `company/credit/${data?.id}`,
    data,
    config
  );

  return res.data;
};

const updateServiceCharge = async (token, data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + `company/service/${data?.id}`,
    data,
    config
  );

  return res.data;
};

const deleteCompany = async (token, id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.delete(API_URL + `company/${id}`, config);

  return res.data;
};

const getEmployees = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL + "company/employees", config);

  return res.data;
};

const updateEmployeeStatus = async (token, { id, status }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.put(
    API_URL + `user/status/${id}`,
    { status },
    config
  );

  return res.data;
};

const apiUser = {
  register,
  login,
  getMe,
  updatePassword,
  forgetPassword,
  resetPassword,
  logout,
  createUser,
  getUser,
  getAllUsers,
  updateUser,
  userExists,
  getCompany,
  getCompanyById,
  getCompanies,
  getMyCompany,
  createCompany,
  updateCompany,
  updateCreditLimit,
  updateServiceCharge,
  deleteCompany,
  getEmployees,
  updateCompanyStatus,
  updateEmployeeStatus,
};

export default apiUser;
