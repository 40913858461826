import moment from "moment";
import { Dropdown, Nav } from "react-bootstrap";
import React, { useEffect, useState } from "react";

import excelExport from "../../utils/excelExport";
import Overall from "../../components/Transaction/Overall";
import convertNumbThousand from "../../utils/convertNumbThousand";
import SuperAgentBilling from "../../components/Transaction/SuperAgentBilling";
import { useDispatch, useSelector } from "react-redux";
import { getBillings } from "../../redux2/billing/billingSlice";
import Loading from "../../components/Loading";
import ChangeExchangeRate from "../../components/ExchangeRate/changeExchangeRate";
import StatCard from "./StatCard";
import bankListData from "./components/BankList";
import { USER_ROLE_EMPLOYEE } from "../../common/constants";
import { formatNumber } from "../../utils/convertToNumber";
import DiscountModal from "./Modals/DiscountModal";
import ButtonPrimary from "../../components/Button/ButtonPrimary";
// import KachaLoanList from "./components/KachaLoanList";

const BillingList = ({ query }) => {
  const { billings, isLoading } = useSelector((state) => state.billing);
  const { user } = useSelector((state) => state.auth);
  const [current, setCurrent] = useState("Overall Billing");
  const [openExchange, setOpenExchange] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const NavData = [
    { name: "Overall Billing" },
    { name: "Super Agent Billing" },
  ];

  query.page = currentPage || 1;
  query.limit = 10;

  const bankAmount = billings?.bankBookings?.reduce(
    (acc, { _id, totalAmount }) => {
      acc[_id] = totalAmount;
      return acc;
    },
    {}
  );

  const statData = [
    {
      title: "Total Deposits Amount",
      data:
        formatNumber(`${billings?.result?.[0]?.wallet.toFixed(2)}`) + " birr",
      // data: "12,000,000 birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-wallet2"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M12.136.326A1.5 1.5 0 0 1 14 1.78V3h.5A1.5 1.5 0 0 1 16 4.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 13.5v-9a1.5 1.5 0 0 1 1.432-1.499L12.136.326zM5.562 3H13V1.78a.5.5 0 0 0-.621-.484L5.562 3zM1.5 4a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "primary",
    },
    {
      title: "Total Sales",
      data:
        convertNumbThousand(billings?.result?.[1]?.totalAmount || 0) +
        // convertNumbThousand(billings?.totalSales + calculateTotalCredit || 0) +
        " birr",
      // data: getTotalSales() + " birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "success",
    },
    {
      title: "Base Price",
      data:
        formatNumber(`${billings?.result?.[1]?.price.toFixed(2)}`) + " birr",
      // data: "8,750,000 birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "NDC Base Price",
      data:
        formatNumber(`${billings?.result?.[1]?.NDCPrice?.toFixed(2)}`) +
        " birr",
      // data: "8,750,000 birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Service Charge",
      data: convertNumbThousand(billings?.result?.[1]?.serviceCharge) + " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="IconChangeColor"
          height="30"
          width="30"
        >
          <path
            d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm5.86-1.55h0L4.71,2.29A1,1,0,0,0,3.29,3.71L4.59,5H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H18.59l2.7,2.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm-.74,2.09,1.34,1.34A1,1,0,0,1,12,13a1,1,0,0,1-1-1A1,1,0,0,1,11.12,11.54ZM4,17a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H6.59l3.1,3.1A3,3,0,0,0,9,12a3,3,0,0,0,3,3,3,3,0,0,0,1.9-.69L16.59,17ZM20,5H12.66a1,1,0,0,0,0,2H20a1,1,0,0,1,1,1v7.34a1,1,0,1,0,2,0V8A3,3,0,0,0,20,5Zm-1,7a1,1,0,1,0-1,1A1,1,0,0,0,19,12Z"
            id="mainIconPathAttribute"
          ></path>
        </svg>
      ),
      color: "success",
    },
    {
      title: "NDC Service Charge",
      data:
        convertNumbThousand(billings?.result?.[1]?.NDCServiceCharge) + " birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          data-name="Layer 1"
          viewBox="0 0 24 24"
          id="IconChangeColor"
          height="30"
          width="30"
        >
          <path
            d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm5.86-1.55h0L4.71,2.29A1,1,0,0,0,3.29,3.71L4.59,5H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H18.59l2.7,2.71a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Zm-.74,2.09,1.34,1.34A1,1,0,0,1,12,13a1,1,0,0,1-1-1A1,1,0,0,1,11.12,11.54ZM4,17a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H6.59l3.1,3.1A3,3,0,0,0,9,12a3,3,0,0,0,3,3,3,3,0,0,0,1.9-.69L16.59,17ZM20,5H12.66a1,1,0,0,0,0,2H20a1,1,0,0,1,1,1v7.34a1,1,0,1,0,2,0V8A3,3,0,0,0,20,5Zm-1,7a1,1,0,1,0-1,1A1,1,0,0,0,19,12Z"
            id="mainIconPathAttribute"
          ></path>
        </svg>
      ),
      color: "success",
    },
    {
      title: "System Fee",
      data: convertNumbThousand(billings?.result?.[1]?.systemFee) + " birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "success",
    },
    {
      title: "Net Revenue",
      data: convertNumbThousand(billings?.result?.[1]?.netRevenue) + " birr",
      // data: "8,750,000 birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },

    {
      title: "Total Tax and Penalty",
      data: convertNumbThousand(billings?.result?.[1]?.taxandpenalty) + " birr",
      // data: convertNumbThousand(calculateTaxPenalty) + " birr",
      // data: "8,750,000 birr",
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Fare Difference",
      data: formatNumber(billings?.result?.[1]?.fareDifference) || 0,
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Total Sold Tickets",
      // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
      data: formatNumber(billings?.ticketCount) || 0,
      icon: (
        // ticket svg
        <svg
          id="icon-ticket"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          // strokeLinecap="round"
          // strokeLinejoin="round"
          // className="feather feather-dollar-sign"
        >
          <path
            d="M19,7V19a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V7A2,2,0,0,1,7,5h5l2-2h4a2,2,0,0,1,2,2Z"
            id="mainIconPathAttribute"
          ></path>
          <polyline
            points="16 2 16 8 22 8"
            id="mainIconPathAttribute"
          ></polyline>
          <line
            x1="12"
            y1="18"
            x2="12"
            y2="12"
            id="mainIconPathAttribute"
          ></line>
          <line
            x1="9"
            y1="15"
            x2="15"
            y2="15"
            id="mainIconPathAttribute"
          ></line>
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Total Sold NDC Tickets",
      // data: formatNumber(countAllAgentTickets() + countAllSuperTickets()) || 0,
      data: formatNumber(billings?.NDCTicketCount) || 0,
      icon: (
        // ticket svg
        <svg
          id="icon-ticket"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          // strokeLinecap="round"
          // strokeLinejoin="round"
          // className="feather feather-dollar-sign"
        >
          <path
            d="M19,7V19a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V7A2,2,0,0,1,7,5h5l2-2h4a2,2,0,0,1,2,2Z"
            id="mainIconPathAttribute"
          ></path>
          <polyline
            points="16 2 16 8 22 8"
            id="mainIconPathAttribute"
          ></polyline>
          <line
            x1="12"
            y1="18"
            x2="12"
            y2="12"
            id="mainIconPathAttribute"
          ></line>
          <line
            x1="9"
            y1="15"
            x2="15"
            y2="15"
            id="mainIconPathAttribute"
          ></line>
        </svg>
      ),
      color: "warning",
    },

    {
      title: "Corporate Sales",
      data: convertNumbThousand(billings?.creditAmount) + " birr",
      // data: "8,750,000 birr",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Total Short-term Credit",
      data: convertNumbThousand(billings?.result?.[0]?.STCredit) + " birr",

      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          className="bi bi-credit-card-fill"
          viewBox="0 0 16 16"
          id="IconChangeColor"
        >
          {" "}
          <path
            d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1H0V4zm0 3v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7H0zm3 2h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1z"
            id="mainIconPathAttribute"
          ></path>{" "}
        </svg>
      ),
      color: "warning",
    },
    {
      title: "Total Voided Tickets",
      data: formatNumber(billings?.voidedTicketCount) || 0,
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Total Void Fee",
      data: formatNumber(billings?.result?.[1]?.voidSC) + " birr" || 0,
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Total Refunded Tickets",
      data: formatNumber(billings?.refundedTicketCount) || 0,
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Total Exchanged Tickets",
      data: formatNumber(billings?.exchangeTicketCount) || 0,
      icon: (
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-dollar-sign"
        >
          <line x1="12" y1="1" x2="12" y2="23"></line>
          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
        </svg>
      ),
      color: "danger",
    },
    {
      title: "Total Agents",
      data: billings?.totalAgents || 0,
      icon: (
        // person svg
        <svg
          id="icon-revenue"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="1"
          // strokeLinecap="round"
          // strokeLinejoin="round"
          // className="feather feather-dollar-sign"
        >
          {/* <path
                d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"
                id="mainIconPathAttribute"
              ></path> */}
          <path
            d="M12,6a3,3,0,1,0,3,3A3,3,0,0,0,12,6Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,10Z"
            id="mainIconPathAttribute"
          ></path>
          <path
            d="M12,14a5,5,0,0,0-5,5v1a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V19A5,5,0,0,0,12,14Zm0,8a3,3,0,0"
            id="mainIconPathAttribute"
          ></path>
        </svg>
      ),
      color: "warning",
    },
  ];

  const renderTransaction = () => {
    switch (current) {
      case "Overall Billing":
        return (
          <Overall
            billings={billings}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
      case "Super Agent Billing":
        return <SuperAgentBilling billings={billings?.superBillings} />;
      default:
        return (
          <Overall
            billings={billings}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        );
    }
  };

  function handleExport() {
    let fileName = "Billing Report " + moment().format("DD-MM-YYYY");

    let data = billings?.billings?.data?.map((billing, index) => {
      return {
        "S/N": index + 1,
        Status: "Active",
        "Company Name": billing?.company ? billing?.company?.name : "Deleted",
        "Transaction ID": billing.transaction
          ? billing.transaction
          : billing._id,
        "Ticket Number": billing?.ticketNumber?.join(", "),
        "Base Amount": formatNumber(billing?.deposit),
        "Payment Method": billing.bank,
        "Service Charge": billing.serviceCharge || "0.00",
        "Paid By": billing?.paidBy,
        Balance: billing?.balance ? formatNumber(billing?.balance) : "0.00",
        "Created At": moment(billing.createdAt).format("MMM DD, YYYY"),
      };
    });
    let fileName2 = "Super Billing Report " + moment().format("DD-MM-YY");
    let superAgentData = billings?.superBillings.map((billing, index) => {
      return {
        "S/N": index + 1,
        Status: billing.status,
        "Company Name": "Sterling travels & tour",
        "Transaction ID": billing.transaction
          ? billing.transaction
          : billing._id,
        "Ticket Number": billing?.ticketNumber?.join(", "),
        "Corporate Name": billing?.corporateName,
        "Base Amount": formatNumber(billing.deposit || billing.amount),
        "Payment Method": billing.bank,
        "Service Charge": billing.serviceCharge || "0.00",
        "Paid By": billing?.paidBy,
        "Created At": moment(billing.createdAt).format("MMM DD, YYYY"),
      };
    });

    excelExport(data, fileName);
    excelExport(superAgentData, fileName2);
  }

  useEffect(() => {
    dispatch(getBillings(query));
  }, [query, dispatch, currentPage]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {user?.role !== USER_ROLE_EMPLOYEE && (
        <>
          {statData.map(({ icon, color, title, data }, index) => (
            <StatCard
              key={index}
              icon={icon}
              color={color}
              title={title}
              data={data}
            />
          ))}
          <h1>
            <span className="px-2 py-4 text-2xl font-semibold">
              Bank Transaction Stat
            </span>
          </h1>
          {bankListData(bankAmount, formatNumber).map(
            ({ icon, color, title, data }, index) => (
              <StatCard
                key={index}
                icon={icon}
                color={color}
                title={title}
                data={data}
              />
            )
          )}
        </>
      )}

      <div className="flex-wrap my-4 d-flex justify-content-between align-items-center">
        <div className="mt-3 mb-4 rounded shadow-sm card-tabs mt-sm-0 mb-xxl-0">
          <Nav as="ul" className="nav nav-tabs">
            {NavData.map(({ name }, index) => (
              <Nav.Item
                as="li"
                key={index}
                className="nav-item"
                onClick={() => setCurrent(name)}
              >
                <Nav.Link
                  className="px-4 py-2 text-4xl font-bold nav-link"
                  eventKey={name}
                  active={current === name}
                >
                  {name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </div>

        <div className="flex gap-2 items-center mx-4">
          {/* <Dropdown className="dropdown text-sans-serif">
            <Dropdown.Toggle
              // id="dropdown"
              variant=""
              className="p-2 px-4 mx-3 text-white border-main-100 border-1"
              type="button"
              id="order-dropdown-0"
              data-toggle="dropdown"
              data-boundary="viewport"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="flex">
                <h1 className="mx-2">Action</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <circle fill="#000000" cx={5} cy={12} r={2} />
                      <circle fill="#000000" cx={12} cy={12} r={2} />
                      <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                  </svg>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="py-0 border dropdown-menu dropdown-menu-right"
              aria-labelledby="order-dropdown-0"
              style={{ minWidth: "20rem" }}
            >
              <div className="px-4 mx-2 mt-2 text-lg font-medium text-black">
                <div className="py-2 hover:text-main">
                  <KachaLoanList />
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown> */}
          <div className="mx-1 mb-4 text-white btn bg-secondary border-main-100 mb-xxl-0">
            <button onClick={() => setOpenExchange(true)}>
              Update Exchange Rate
            </button>
          </div>

          <ButtonPrimary
            className="mb-4 text-black rounded-3xl border-1 border-main bg-slate-50 hover:text-white hover:bg-main-900 focus:outline-none focus:ring-2 focus:ring-main focus:ring-opacity-50 mb-xxl-0"
            onClick={() => setOpenDiscount(true)}
          >
            Discount
          </ButtonPrimary>

          <div
            className="mb-4 text-white btn bg-main hover:bg-main-800 border-main-100 mb-xxl-0"
            onClick={handleExport}
          >
            <i className="far fa-file-word me-2"></i>Generate Report
          </div>
        </div>
      </div>

      <div>
        <h3 className="text-2xl font-black text-primary">Transaction</h3>
        {renderTransaction()}
      </div>
      <ChangeExchangeRate show={openExchange} setOpen={setOpenExchange} />
      <DiscountModal show={openDiscount} setOpen={setOpenDiscount} />
    </>
  );
};

export default BillingList;
