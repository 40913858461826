import moment from "moment";
import React, { useEffect } from "react";
import TicketDetail from "./TicketDetails/TicketDetail.jsx";
import { getCarrierInfo, getFlightName } from "../../../utils/flightInfo";

import logo from "../../../images/logo.png";
import hulu from "../../../images/hulutravel.png";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyById } from "../../../redux2/user/userSlice.js";
import Loading from "../../../components/Loading/index.jsx";

export const TicketPrint = React.forwardRef(
  (
    {
      passengerList,
      airReservation,
      reservation,
      ticketNumbers,
      flightDestination,
      passengerInfo,
      companyInfo,
      seatAssignment,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    let departFlight = [];
    let returnFlight = [];

    if (airReservation?.AirSegment instanceof Array) {
      returnFlight = airReservation?.AirSegment.filter((item) => {
        return item?._attributes?.Group === "1";
      });
      departFlight = airReservation?.AirSegment.filter((item) => {
        return item?._attributes?.Group === "0";
      });
    } else {
      departFlight.push(airReservation?.AirSegment);
    }

    let carrierLogo = getCarrierInfo(
      airReservation?.AirSegment?._attributes?.Carrier ||
        airReservation?.AirSegment?.[0]?._attributes?.Carrier
    )?.Logo;

    const { company, isLoading } = useSelector((state) => state.user);

    useEffect(() => {
      dispatch(getCompanyById(companyInfo));
    }, [dispatch, companyInfo]);

    if (isLoading) return <Loading />;

    return (
      <div className="mx-10 my-4 text-black ticket-print" ref={ref}>
        <div className="my-1 text-lg font-semibold flight-header">
          <>
            {airReservation?.AirSegment instanceof Array ? (
              <>
                {/* <h1>
                  {moment(
                    departFlight?.[departFlight.length - 1]?._attributes
                      ?.DepartureTime
                  ).format("DD-MM-YYYY")}{" "}
                </h1> 
                <h1 className="tracking-wider uppercase">
                  {moment(
                    returnFlight?.[departFlight.length - 1]?._attributes
                      ?.ArrivalTime
                  ).format("DD-MM-YYYY")}{" "}
                  </h1>
                  */}
                <h1>Trip to {getFlightName(flightDestination)}</h1>
              </>
            ) : (
              <>
                {/* {moment(
                  airReservation?.AirSegment?._attributes?.DepartureTime
                ).format("DD-MM-YYYY")}{" "} */}
                Trip to {""}
                {getFlightName(flightDestination)}
              </>
            )}
          </>
        </div>
        <hr />
        <div className="passenger-header">
          <div className="">
            <p>PREPARED FOR </p>
            <div className="flex flex-col">
              {passengerList?.map((passenger, index) =>
                passenger?.[0]?.Middle ? (
                  <h1
                    className="text-xl font-normal tracking-wide text-gray-600"
                    key={index}
                  >
                    {passenger?.[0]?.Prefix && passenger?.[0]?.Prefix + ". "}
                    <span className="font-semibold uppercase">
                      {passenger?.[0]?.First +
                        " " +
                        passenger?.[0]?.Middle +
                        " " +
                        passenger?.[0]?.Last}
                    </span>
                  </h1>
                ) : (
                  <h1 className="text-xl font-normal tracking-wide text-gray-600">
                    {passenger?.[0]?.Prefix && passenger?.[0]?.Prefix + ". "}
                    <span className="font-semibold uppercase">
                      {passenger?.[0]?.First + " " + passenger?.[0]?.Last}
                    </span>
                  </h1>
                )
              )}
            </div>
            <div className="flex flex-row gap-5 justify-between font-light tracking-wide text-gray-500 text-md">
              <p className="">
                Confirmation number{": "}
                <span className="font-bold">
                  {
                    airReservation?.SupplierLocator?._attributes
                      ?.SupplierLocatorCode
                  }
                </span>
              </p>
              <p className="">
                Reservation Code{": "}
                <span className="font-bold">
                  {reservation?._attributes?.LocatorCode}
                </span>
              </p>
            </div>
          </div>
          <div className="text-sm">
            <p>Passport Number</p>
            <div className="flex flex-col">
              {passengerInfo?.map((passenger, index) => (
                <h1 className="text-lg font-normal tracking-wide text-gray-600">
                  {passenger?.passportNumber}
                </h1>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={carrierLogo}
              alt="logo"
              className="object-cover w-32 h-20"
            />
          </div>
        </div>
        <div className="line"></div>

        {airReservation?.AirSegment instanceof Array ? (
          airReservation?.AirSegment.map((item, index) => {
            return (
              <TicketDetail
                data={item}
                fareInfo={airReservation?.AirPricingInfo?.[0]?.FareInfo[index]}
                passengerList={passengerList}
                tickets={ticketNumbers}
                flightDetail={item.FlightDetails._attributes}
                seatAssignment={seatAssignment}
                key={index}
                index={index}
              />
            );
          })
        ) : (
          <TicketDetail
            data={airReservation?.AirSegment}
            passengerList={passengerList}
            fareInfo={airReservation?.AirPricingInfo?.[0]?.FareInfo}
            tickets={ticketNumbers}
            flightDetail={
              airReservation?.AirSegment?.FlightDetails?._attributes
            }
            seatAssignment={seatAssignment}
          />
        )}

        <div className="line"></div>

        <div className="p-2 my-2 border-1 border-slate-400">
          <h1 className="py-2 font-semibold text-md text-grey-700">
            Baggage Information
          </h1>
          <div className="row">
            <p className="col-2 sm:px-10">Flight</p>
            <p className="col-3">Baggage Allowance</p>
            <p className="col-2">Fare Basis</p>
            <p className="col-3">Not Valid Before</p>
            <p className="col-2">Not Valid After</p>
          </div>
          <div className="text-xs row text-slate-500">
            {passengerList?.length > 1
              ? passengerList?.map((_, i) =>
                  airReservation?.AirSegment instanceof Array
                    ? airReservation?.AirSegment.map((item, index) => {
                        return FlightDetail(
                          airReservation?.AirPricingInfo?.[i]?.FareInfo[
                            index
                          ] ||
                            airReservation?.AirPricingInfo?.FareInfo?.[index],
                          item?._attributes,
                          i
                        );
                      })
                    : FlightDetail(
                        airReservation?.AirPricingInfo?.[i]?.FareInfo ||
                          airReservation?.AirPricingInfo?.FareInfo,
                        airReservation?.AirSegment._attributes,
                        i
                      )
                )
              : airReservation?.AirSegment instanceof Array
              ? airReservation?.AirSegment.map((item, index) => {
                  return FlightDetail(
                    airReservation?.AirPricingInfo?.FareInfo?.[index],
                    item?._attributes
                  );
                })
              : FlightDetail(
                  airReservation?.AirPricingInfo?.FareInfo,
                  airReservation?.AirSegment?._attributes
                )}
          </div>
        </div>

        <div className="flex justify-between px-1 text-xs">
          {company ? (
            company._id.toString() === "64e45eeb53b8374c093e5b68" ? (
              <>
                <img
                  src={hulu}
                  alt=""
                  className="hidden object-cover -mt-4 md:block md:w-56"
                />
                <div className="text-xs">
                  <div className="space-y-1">
                    <h3>Head Office: Near the National Theater </h3>
                    <h3 className="text-center sm:ml-14">
                      Addis Ababa, Ethiopia
                    </h3>
                  </div>
                </div>
                <div>
                  <h2>Agency Office Phone:</h2>
                  <h3>+251 115 511333 </h3>
                  <h3>+251 115 514666</h3>
                </div>
                <div>
                  <h2>Agency Mobile Phone:</h2>
                  <h3>+251 922 238193</h3>
                </div>
              </>
            ) : (
              <>
                {/* <h1 className="text-xl font-bold">Booking Technologies PLC</h1> */}
                <h1 className="text-xl font-bold">{company?.name}</h1>
                <div className="text-xs">
                  <div className="space-y-1">
                    <h3>Head Office: {company?.address} </h3>
                    <h3 className="text-center sm:ml-3">
                      {/* Addis Ababa, Ethiopia */}
                      Ethiopia
                    </h3>
                  </div>
                </div>
                <div>
                  <h2>Agency Office Phone:</h2>
                  <h3>{company?.officeNumber}</h3>
                  <h3>{company?.officeNumber2}</h3>
                </div>
              </>
            )
          ) : (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "150px", height: "38px" }}
                // className="hidden object-cover -mt-4 md:block md:w-56"
              />
              <div className="text-xs">
                <div className="space-y-1">
                  <h3>Head Office: Near the National Theater </h3>
                  <h3 className="text-center sm:ml-14">
                    Addis Ababa, Ethiopia
                  </h3>
                </div>
              </div>
              <div>
                <h2>Agency Office Phone:</h2>
                <h3>+251 115 511333 </h3>
                <h3>+251 115 514666</h3>
              </div>
              <div>
                <h2>Agency Mobile Phone:</h2>
                <h3>+251 922 238193</h3>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

function FlightDetail(fareInfo, segment, index) {
  return (
    <div className="row sm:mx-2">
      <h1 className="col-2">
        {segment?.Origin} - {segment?.Destination}
      </h1>
      <div className="col-3">
        {fareInfo?.BaggageAllowance?.NumberOfPieces ? (
          <h1>
            <span className="font-semibold text-grey-700">
              {fareInfo?.BaggageAllowance?.NumberOfPieces?._text}{" "}
            </span>
            Piece(s) Plan
          </h1>
        ) : (
          <h1>
            <span className="font-semibold text-grey-700">
              {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Value}{" "}
            </span>
            {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Unit} Plan
          </h1>
        )}
      </div>
      <div className="col-2">
        {/* <h2 className="font-semibold text-grey-700">Fare Basis:</h2> */}
        <div className="text-grey-700">{fareInfo?._attributes?.FareBasis}</div>
      </div>
      <div className="col-3">
        {/* <h2 className="font-semibold text-grey-700">Not Valid Before:</h2> */}
        <h1 className="text-grey-700">
          {moment(fareInfo?._attributes?.NotValidBefore).format("MMM DD, YYYY")}
        </h1>
      </div>
      <div className="col-2">
        {/* <h2 className="font-semibold text-grey-700">Not Valid After:</h2> */}
        <h1 className="text-grey-700">
          {" "}
          {moment(fareInfo?._attributes?.NotValidAfter).format("MMM DD, YYYY")}
        </h1>
      </div>
    </div>
  );
}
