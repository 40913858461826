import axios from "axios";

import API_URL from "../redux2";

const retrieveTicket = async (pnr, setLoading) => {
  if (!pnr) return;

  setLoading(true);
  try {
    const res = await axios.post(API_URL + "flight/retrieve-pnr", {
      PNR: pnr,
    });

    return res.data;
  } catch (err) {
    console.log(err);
  } finally {
    setLoading(false);
  }
};

const flightPricing = async (data, setLoading) => {
  setLoading(true);
  try {
    const response = await axios.post(API_URL + "flight/pricing-2", data);

    return response.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const modifyPNR = async (data, setLoading) => {
  setLoading(true);
  try {
    const response = await axios.post(API_URL + "flight/modify-booking", data);

    return response.data;
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const cancelDuplicateBooking = async (data, setLoading, setError) => {
  setLoading(true);
  try {
    const response = await axios.post(
      API_URL + "flight/cancel-segment-duplicated",
      data
    );

    return response.data;
  } catch (error) {
    setError(error?.response?.data?.message);
    console.log(error);
  } finally {
    setLoading(false);
  }
};

export { retrieveTicket, flightPricing, modifyPNR, cancelDuplicateBooking };
