import axios from "axios";

import API_URL from "..";
import { API } from "../client";

const API_URL_DISCOUNT = API_URL + "discount/";

// Create discount
const createDiscount = async (token, discount) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await axios.post(API_URL_DISCOUNT, discount, config);

  return res.data;
};

// Get discount by destination
const getDiscountByDestination = async (token, destination) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const res = await API.get(API_URL_DISCOUNT + `${destination}`, config);

  return res.data;
};

const discountApi = {
  createDiscount,
  getDiscountByDestination,
};

export default discountApi;
