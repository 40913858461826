import { Button, MenuItem, Switch, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { CompanySchema2 } from "../user.util";
import { LoadingButton } from "@mui/lab";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const AddWallet = ({
  openAddWallet,
  setOpenAddWallet,
  handleSubmitCompany,
}) => {
  const [collapsed, setCollapsed] = useState(false);

  const [pricingRules, setPricingRules] = useState([
    { minPrice: 0, maxPrice: 34999, defaultCharge: 11 },
    { minPrice: 35000, maxPrice: 49999, defaultCharge: 15 },
    { minPrice: 50000, maxPrice: 59999, defaultCharge: 18 },
    { minPrice: 60000, maxPrice: 69999, defaultCharge: 22 },
    { minPrice: 70000, maxPrice: 79999, defaultCharge: 25 },
    { minPrice: 80000, maxPrice: 89999, defaultCharge: 29 },
    { minPrice: 90000, maxPrice: 99999, defaultCharge: 33 },
    { minPrice: 100000, maxPrice: 500000, defaultCharge: 36 },
    // { minPrice: 200000, maxPrice: 500000, defaultCharge: 54 },
  ]);

  // const [businessPricingRules] = useState([
  //   { minPrice: 20000, maxPrice: 34999, defaultCharge: 14 },
  //   { minPrice: 35000, maxPrice: 49999, defaultCharge: 18 },
  //   { minPrice: 50000, maxPrice: 69999, defaultCharge: 21 },
  //   { minPrice: 70000, maxPrice: 89999, defaultCharge: 25 },
  //   { minPrice: 90000, maxPrice: 109999, defaultCharge: 28 },
  //   { minPrice: 110000, maxPrice: 129999, defaultCharge: 32 },
  //   { minPrice: 130000, maxPrice: 149999, defaultCharge: 35 },
  //   { minPrice: 150000, maxPrice: 199999, defaultCharge: 48 },
  //   { minPrice: 200000, maxPrice: 500000, defaultCharge: 57 },
  // ]);

  const columnsCount = 2;

  const initialValues = pricingRules.reduce(
    (acc, rule, index) => ({
      ...acc,
      [`minPrice_${index}`]: rule.minPrice,
      [`maxPrice_${index}`]: rule.maxPrice,
      [`charge_${index}`]:
        rule.charge !== undefined ? rule.charge : rule.defaultCharge,
    }),
    {
      bank: "",
      deposit: "",
      transactionId: "",
      amount: "",
      creditType: "",
      creditLimit: "",
      serviceChargeLocal: 100,
      serviceChargeInternationalBusiness: 36,
      isLabor: false,
      isOneWay: false,
      serviceChargeLabor: 7,
      serviceChargeOneWay: 7,
    }
  );

  // const businessInitialValues = businessPricingRules.reduce(
  //   (acc, rule, index) => ({
  //     ...acc,
  //     [`businessMinPrice_${index}`]: rule.minPrice,
  //     [`businessMaxPrice_${index}`]: rule.maxPrice,
  //     [`businessCharge_${index}`]:
  //       rule.charge !== undefined ? rule.charge : rule.defaultCharge,
  //   }),
  //   {}
  // );

  // const combinedInitialValues = {
  //   ...initialValues,
  //   ...businessInitialValues,
  // };

  return (
    <div>
      <Modal
        className="modal fade"
        show={openAddWallet}
        onHide={setOpenAddWallet}
        size="lg"
      >
        <div className="" role="document">
          <div className="">
            <div className="modal-header">
              <h4 className="modal-title fs-20">Add To Wallet</h4>
              <button
                type="button"
                className="btn-close"
                onClick={() => setOpenAddWallet(false)}
                data-dismiss="modal"
              ></button>
            </div>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close text-gray"></i>
              <div className="add-contact-box">
                <Formik
                  initialValues={initialValues}
                  validationSchema={CompanySchema2}
                  // onSubmit={(values) =>
                  //   dispatch(
                  //     employeeActions.add({
                  //       ...values,
                  //       phone: formatPhone(values.phone),
                  //     })
                  //   )
                  // }
                  // onSubmit={handleSubmitCompany}
                  onSubmit={(values) => {
                    const transformedPricingRules = Array.from(
                      { length: pricingRules.length },
                      (_, index) => ({
                        minPrice: values[`minPrice_${index}`],
                        maxPrice: values[`maxPrice_${index}`],
                        charge: values[`charge_${index}`],
                      })
                    );

                    // const transformedBusinessPricingRules = Array.from(
                    //   { length: businessPricingRules.length },
                    //   (_, index) => ({
                    //     minPrice: values[`businessMinPrice_${index}`],
                    //     maxPrice: values[`businessMaxPrice_${index}`],
                    //     charge: values[`businessCharge_${index}`],
                    //   })
                    // );

                    const data = {
                      ...values,
                      serviceChargeInternational: transformedPricingRules,
                      serviceChargeInternationalBusiness:
                        values.serviceChargeInternationalBusiness,
                      creditLimit:
                        values.creditType === "" ? 0 : values.creditLimit,
                      serviceChargeLabor: values.isLabor
                        ? values.serviceChargeLabor
                        : 0,
                      serviceChargeOneWay: values.isOneWay
                        ? values.serviceChargeOneWay
                        : 0,
                    };

                    handleSubmitCompany(data);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit} className="flex items-end">
                      <div className="flex flex-col">
                        <div className="row">
                          <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              labelid="demo-simple-select-label"
                              id="bank"
                              name="bank"
                              size="small"
                              value={values.bank}
                              label="Bank"
                              onChange={handleChange}
                              select
                              error={touched.bank && Boolean(errors.bank)}
                              helperText={touched.bank && errors.bank}
                            >
                              <MenuItem value="cbe">CBE</MenuItem>
                              <MenuItem value="awash">Awash</MenuItem>
                              <MenuItem value="abyssinia">Abyssinia</MenuItem>
                            </TextField>
                          </div>

                          <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              type="number"
                              id="deposit"
                              size="small"
                              variant="outlined"
                              name="deposit"
                              placeholder="Deposit Amount"
                              label="Deposit Amount"
                              value={values.deposit}
                              // disabled={checker?.firstName ?? false}
                              onChange={handleChange}
                              error={touched.deposit && Boolean(errors.deposit)}
                              helperText={touched.deposit && errors.deposit}
                            />
                          </div>
                          <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="transactionId"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="transactionId"
                              placeholder="Transaction ID"
                              label="Transaction ID"
                              value={values.transactionId}
                              onChange={handleChange}
                              error={
                                touched.transactionId &&
                                Boolean(errors.transactionId)
                              }
                              helperText={
                                touched.transactionId && errors.transactionId
                              }
                            />
                          </div>
                          {/* <div className="mb-8 col-lg-6">
                        <TextField
                          fullWidth
                          id="amount"
                          type="number"
                          size="small"
                          variant="outlined"
                          name="amount"
                          placeholder="Amount"
                          label="Amount"
                          value={values.amount}
                          onChange={handleChange}
                          error={touched.amount && Boolean(errors.amount)}
                          helperText={touched.amount && errors.amount}
                        />
                      </div> */}

                          <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="creditType"
                              type="text"
                              size="small"
                              variant="outlined"
                              name="creditType"
                              placeholder="Credit Type"
                              label="Credit Type"
                              select
                              value={values.creditType}
                              onChange={handleChange}
                              defaultValue="none"
                              error={
                                touched.creditType && Boolean(errors.creditType)
                              }
                              helperText={
                                touched.creditType && errors.creditType
                              }
                            >
                              <MenuItem value="">none</MenuItem>
                              <MenuItem value="oneDay">One Day Credit</MenuItem>
                              <MenuItem value="oneWeek" disabled>
                                One Week Credit
                              </MenuItem>
                              <MenuItem value="twoWeek" disabled>
                                Two Week Credit
                              </MenuItem>
                            </TextField>
                          </div>
                          <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="creditLimit"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="creditLimit"
                              placeholder="Credit Limit"
                              label="Credit Limit"
                              disabled={values.creditType === ""}
                              value={values.creditLimit}
                              onChange={handleChange}
                              required={values.creditType !== ""}
                              error={
                                touched.creditLimit &&
                                Boolean(errors.creditLimit)
                              }
                              helperText={
                                touched.creditLimit && errors.creditLimit
                              }
                            />
                          </div>
                          <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="serviceChargeLocal"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeLocal"
                              placeholder="Local Service Charge"
                              label="Local Service Charge (in Birr)"
                              value={values.serviceChargeLocal}
                              onChange={handleChange}
                              error={
                                touched.serviceChargeLocal &&
                                Boolean(errors.serviceChargeLocal)
                              }
                              helperText={
                                touched.serviceChargeLocal &&
                                errors.serviceChargeLocal
                              }
                            />
                          </div>

                          <div className="col-md-6">
                            <button
                              className="flex justify-between items-center px-2 w-full h-11 rounded-md border-gray-300 border-1"
                              type="button"
                              onClick={() => setCollapsed(!collapsed)}
                            >
                              <h3 className="my-3 text-xs text-gray-600">
                                International Economy Service Charge (in
                                Dollars)
                              </h3>
                              <IconButton
                                onClick={() => setCollapsed(!collapsed)}
                              >
                                {collapsed ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </button>

                            {collapsed && (
                              <div>
                                <p className="my-3 text-xs font-light">
                                  You can set a custom charge for each price
                                  range. If you leave a price range empty, the
                                  default charge will be used.
                                </p>

                                <ul className="mb-4 col-md-12">
                                  <div className="row">
                                    {[...Array(columnsCount)].map(
                                      (_, colIndex) => (
                                        <div
                                          key={colIndex}
                                          className="col-md-6"
                                        >
                                          {pricingRules
                                            .slice(
                                              colIndex *
                                                Math.ceil(
                                                  pricingRules.length /
                                                    columnsCount
                                                ),
                                              (colIndex + 1) *
                                                Math.ceil(
                                                  pricingRules.length /
                                                    columnsCount
                                                )
                                            )
                                            .map((rule, rowIndex) => (
                                              <li
                                                key={rowIndex}
                                                className="mb-4 col-md-12"
                                              >
                                                {/* Use hidden fields to store minPrice and maxPrice */}
                                                <input
                                                  type="hidden"
                                                  name={`minPrice_${
                                                    colIndex *
                                                      Math.ceil(
                                                        pricingRules.length /
                                                          columnsCount
                                                      ) +
                                                    rowIndex
                                                  }`}
                                                  value={rule.minPrice}
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`maxPrice_${
                                                    colIndex *
                                                      Math.ceil(
                                                        pricingRules.length /
                                                          columnsCount
                                                      ) +
                                                    rowIndex
                                                  }`}
                                                  value={rule.maxPrice}
                                                />

                                                <TextField
                                                  type="number"
                                                  size="small"
                                                  variant="outlined"
                                                  className="w-full"
                                                  label={`Charge for ${rule.minPrice} - ${rule.maxPrice}`}
                                                  value={
                                                    values[
                                                      `charge_${
                                                        colIndex *
                                                          Math.ceil(
                                                            pricingRules.length /
                                                              columnsCount
                                                          ) +
                                                        rowIndex
                                                      }`
                                                    ]
                                                  }
                                                  onChange={handleChange}
                                                  name={`charge_${
                                                    colIndex *
                                                      Math.ceil(
                                                        pricingRules.length /
                                                          columnsCount
                                                      ) +
                                                    rowIndex
                                                  }`}
                                                />
                                              </li>
                                            ))}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </ul>
                              </div>
                            )}
                          </div>

                          {/* <div className="mb-5 col-md-6">
                            <button
                              className="flex justify-between items-center px-2 w-full h-11 rounded-md border-gray-300 border-1"
                              type="button"
                              onClick={() =>
                                setBusinessCollapsed(!businessCollapsed)
                              }
                            >
                              <h3 className="my-3 text-xs text-gray-600">
                                International Business Service Charge (in
                                Dollars)
                              </h3>
                              <IconButton
                                onClick={() =>
                                  setBusinessCollapsed(!businessCollapsed)
                                }
                              >
                                {collapsed ? (
                                  <ExpandLessIcon />
                                ) : (
                                  <ExpandMoreIcon />
                                )}
                              </IconButton>
                            </button>

                            {businessCollapsed && (
                              <div>
                                <p className="my-3 text-xs font-light">
                                  You can set a custom charge for each price
                                  range. If you leave a price range empty, the
                                  default charge will be used.
                                </p>

                                <ul className="mb-4 col-md-12">
                                  <div className="row">
                                    {[...Array(columnsCount)].map(
                                      (_, colIndex) => (
                                        <div
                                          key={colIndex}
                                          className="col-md-6"
                                        >
                                          {businessPricingRules
                                            .slice(
                                              colIndex *
                                                Math.ceil(
                                                  businessPricingRules.length /
                                                    columnsCount
                                                ),
                                              (colIndex + 1) *
                                                Math.ceil(
                                                  businessPricingRules.length /
                                                    columnsCount
                                                )
                                            )
                                            .map((rule, rowIndex) => (
                                              <li
                                                key={rowIndex}
                                                className="mb-4 col-md-12"
                                              >
                                                <input
                                                  type="hidden"
                                                  name={`businessMinPrice_${
                                                    colIndex *
                                                      Math.ceil(
                                                        businessPricingRules.length /
                                                          columnsCount
                                                      ) +
                                                    rowIndex
                                                  }`}
                                                  value={rule.minPrice}
                                                />
                                                <input
                                                  type="hidden"
                                                  name={`businessMaxPrice_${
                                                    colIndex *
                                                      Math.ceil(
                                                        businessPricingRules.length /
                                                          columnsCount
                                                      ) +
                                                    rowIndex
                                                  }`}
                                                  value={rule.maxPrice}
                                                />

                                                <TextField
                                                  type="number"
                                                  size="small"
                                                  variant="outlined"
                                                  className="w-full"
                                                  label={`Charge for ${rule.minPrice} - ${rule.maxPrice}`}
                                                  value={
                                                    values[
                                                      `businessCharge_${
                                                        colIndex *
                                                          Math.ceil(
                                                            businessPricingRules.length /
                                                              columnsCount
                                                          ) +
                                                        rowIndex
                                                      }`
                                                    ]
                                                  }
                                                  onChange={handleChange}
                                                  name={`businessCharge_${
                                                    colIndex *
                                                      Math.ceil(
                                                        businessPricingRules.length /
                                                          columnsCount
                                                      ) +
                                                    rowIndex
                                                  }`}
                                                />
                                              </li>
                                            ))}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </ul>
                              </div>
                            )}
                          </div> */}

                          {/* <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="serviceChargeInternational"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeInternational"
                              placeholder="International Economy Service Charge"
                              label="International Economy Service Charge (in Dollars)"
                              value={values.serviceChargeInternational}
                              onChange={handleChange}
                              error={
                                touched.serviceChargeInternational &&
                                Boolean(errors.serviceChargeInternational)
                              }
                              helperText={
                                touched.serviceChargeInternational &&
                                errors.serviceChargeInternational
                              }
                            />
                          </div> */}
                          <div className="col-md-6">
                            <TextField
                              fullWidth
                              id="serviceChargeInternationalBusiness"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeInternationalBusiness"
                              placeholder="International Business Service Charge"
                              label="International Business Service Charge (in Dollars)"
                              value={values.serviceChargeInternationalBusiness}
                              onChange={handleChange}
                              error={
                                touched.serviceChargeInternationalBusiness &&
                                Boolean(
                                  errors.serviceChargeInternationalBusiness
                                )
                              }
                              helperText={
                                touched.serviceChargeInternationalBusiness &&
                                errors.serviceChargeInternationalBusiness
                              }
                            />
                          </div>

                          <div className="my-2 col-md-6">
                            <Switch
                              checked={values.isLabor}
                              onChange={handleChange}
                              name="isLabor"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <label htmlFor="isLabor" className="text-black">
                              Is Labor Active
                            </label>
                            <div className="my-2">
                              <TextField
                                fullWidth
                                id="serviceChargeLabor"
                                type="number"
                                size="small"
                                variant="outlined"
                                name="serviceChargeLabor"
                                placeholder=" Labor Service Charge"
                                label=" Labor Service Charge (in Dollars)"
                                value={
                                  values.isLabor ? values.serviceChargeLabor : 0
                                }
                                disabled={!values.isLabor}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="my-2 mb-8 col-md-6">
                            <Switch
                              checked={values.isOneWay}
                              onChange={handleChange}
                              name="isOneWay"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                            />
                            <label htmlFor="isOneWay" className="text-black">
                              Is OneWay Active
                            </label>
                            <div className="my-2">
                              <TextField
                                fullWidth
                                id="serviceChargeOneWay"
                                type="number"
                                size="small"
                                variant="outlined"
                                name="serviceChargeOneWay"
                                placeholder="OneWay Service Charge"
                                label=" OneWay Service Charge (in Dollars)"
                                value={
                                  values.isOneWay
                                    ? values.serviceChargeOneWay
                                    : 0
                                }
                                disabled={!values.isOneWay}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <LoadingButton
                          className="mt-8"
                          variant="contained"
                          type="submit"
                          // loading={add?.loading}
                          // onClick={() => {
                          //   setOpenAddWallet(false);
                          // }}
                          style={{
                            backgroundColor: "#FBA82C",
                            color: "#fff",
                          }}
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </form>
                  )}
                </Formik>
                {/* ) : (
              <>Here</>
            )} */}
              </div>
            </div>
            <div className="modal-footer">
              {/* <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); swal("Employee Added!", "Employee has been successfully added!", "success") }}>Add</button> */}
              <Button onClick={() => setOpenAddWallet(false)} variant="danger">
                {" "}
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddWallet;
