import moment from "moment";
import React, { useMemo } from "react";

import { flightSearchList } from "../../../../utils/flightSearchList";
import { getCarrierInfo } from "../../../../utils/flightInfo";
import planeLogo from "../../../../images/planelogo.png";
import "../style.css";

const NDCTicketDetail = ({
  data,
  passengerList,
  TicketDocInfo,
  flightDetail,
  index,
}) => {
  function customDate(date) {
    let d = date?.split("T")?.[0];
    return moment(d).format("dddd DD MMM");
  }

  function getFlightName(code) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMemo(() => {
      return flightSearchList(code)?.filter((flight) => {
        return flight?.code === code;
      })?.[0]?.city;
    }, [code]);
  }

  function getTicketByPassengerID(PassengerID) {
    return TicketDocInfo?.filter((ticket) => {
      return ticket?.PassengerReference?.[0] === PassengerID;
    })[0].TicketDocument;
  }

  return (
    <div
      style={{
        // pageBreakInside: index && index % 2 === 0 ? "always" : "auto",
        pageBreakBefore: index && index % 2 === 0 ? "always" : "auto",
        marginTop: index && index % 2 === 0 ? "35px" : "0px",
      }}
    >
      <div className="line"></div>
      <div className="mt-1 mb-2 dep-daycar">
        <div className="flex justify-start space-x-1">
          <img src={planeLogo} alt="" style={{ width: "25px" }} />
          <div className="flex space-x-4 item-center">
            <h2 className="">
              DEPARTURE:{" "}
              <span className="text-lg font-bold">
                {customDate(data?.Departure?.Date)}
              </span>
            </h2>
            <p className="mt-1 gray-color">
              Please verify flight times prior to departure
            </p>
          </div>
        </div>
      </div>
      {/* end of departure date header */}
      <div className="pass-details-card">
        <div className="pass-det gray-bg-color">
          <h2 className="text-2xl tracking-wide text-gray-700 font-bolder">
            {getCarrierInfo(data?.MarketingCarrier?.AirlineID?.value)?.Name}
          </h2>
          <span className="bold">
            {data?.MarketingCarrier?.AirlineID?.value +
              " " +
              data?.MarketingCarrier?.FlightNumber?.value}
          </span>

          <div className="my-1">
            <p>Duration:</p>
            <p>{data?.Details?.FlightDuration?.Value?.substring(2)}</p>
          </div>
          <div className="my-1">
            <p>Cabin:</p>
            <p>
              {data?.ClassOfService?.MarketingName?.value} /{" "}
              {data?.ClassOfService?.Code?.value}
            </p>
          </div>
          <p className="my-1">Status: Confirmed</p>
        </div>
        <div className="pass-det-container">
          <div className="pass-det-2">
            <div className="pass-det-2-top">
              <div className="w-1/2">
                <p className="text-lg font-bolder">
                  {data?.Departure?.AirportCode?.value}
                </p>
                <p className="tracking-wider">
                  {getFlightName(data?.Departure?.AirportCode?.value)}
                </p>
              </div>
              <div className="w-1/2">
                <p className="text-lg font-bolder">
                  {data?.Arrival?.AirportCode?.value}
                </p>
                <p className="tracking-wider">
                  {getFlightName(data?.Arrival?.AirportCode?.value)}
                </p>
              </div>
            </div>
            <div className="pass-det-time">
              <div className="dep-time">
                <p className="tracking-wide text-gray-700">
                  Departing At: <br />
                  <span className="text-2xl font-bold">
                    {" "}
                    {data?.Departure?.Time.substring(0, 5)}
                  </span>
                </p>
                <p>Terminal:</p>
                <p className="tracking-wide">
                  {flightDetail?.OriginTerminal
                    ? "Terminal " + flightDetail?.OriginTerminal
                    : "Not Available"}
                </p>
              </div>
              <div className="arr-time">
                <p className="tracking-wide text-gray-700">
                  Arriving At: <br />
                  <span className="text-2xl font-bold">
                    {data?.Arrival?.Time.substring(0, 5)}
                  </span>
                </p>
                <p>Terminal:</p>
                <p className="tracking-wide">
                  {flightDetail?.DestinationTerminal
                    ? "Terminal " + flightDetail?.DestinationTerminal
                    : "Not Available"}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-2 space-y-2 pass-plane">
            <h1>
              Aircraft: {data?.Equipment?.AircraftCode?.value} (
              {data?.Equipment?.Name})
            </h1>
            <h1>Distance (in Miles): {data?.Details?.FlightDistance?.Value}</h1>
          </div>
        </div>
      </div>
      <div className="mt-12 pass-bottom sm:mt-2">
        <div className="bottom-pass-details gray-bg-color">
          <p>Passenger Name:</p>
          <p>Seats:</p>
          <p>eTicket Receipt(s):</p>
        </div>
        <div className="bottom-pass-details">
          <div className="flex flex-col">
            {passengerList?.map((passenger, i) => (
              <p key={i}>
                {">> "}
                {passenger?.Name?.Title && passenger?.Name?.Title + ". "}
                {passenger?.Name?.Given[0]?.value +
                  " " +
                  passenger?.Name?.Surname?.value}
              </p>
            ))}
          </div>

          <div className={`${passengerList?.[0]?.Middle ? "-ml-24" : "ml-3"}`}>
            {passengerList?.map((seat, i) => {
              return seat?.[1] ? (
                seat?.[1] instanceof Array ? (
                  seat?.[1]?.map(
                    (item) =>
                      data?._attributes?.Key ===
                        item?._attributes?.SegmentRef && (
                        <p>{item?._attributes?.Seat}</p>
                      )
                  )
                ) : data?._attributes?.Key ===
                  seat?.[1]?._attributes?.SegmentRef ? (
                  <p>{seat?.[1]?._attributes?.Seat}</p>
                ) : (
                  <p>Check-In Required</p>
                )
              ) : (
                <p>Check-In Required</p>
              );
            })}
          </div>
          <div className="">
            {passengerList?.map((passenger, i) => (
              <div key={i}>
                {getTicketByPassengerID(passenger?.ObjectKey)?.map(
                  (ticket, i) => (
                    <p key={i}>{ticket?.TicketDocNbr}</p>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NDCTicketDetail;
