import moment from "moment";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import convertNumbThousand from "../../utils/convertNumbThousand";
import { convertToNumber } from "../../utils/convertToNumber";
import ButtonPrimary from "../Button/ButtonPrimary";
import {
  getCarrierInfo,
  getCarrierLogo,
  showUSDInstruction,
} from "../../utils/flightInfo";
import customTime from "../../utils/customTime";
import Locals from "../../utils/localFlights";
import { NON_DOCUMENT_HOLDER } from "../../common/constants";

const RoundTrip = ({
  className = "",
  flights,
  flightDetail,
  flight,
  setTotalPrice,
  isReturn,
  setIsReturn,
  setDepartData,
  charge,
  passengerTypeState,
  currencyStates,
  rateDifference,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  let indexFlight = isReturn ? 1 : 0;

  if (!flights && flight && flightDetail) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-32 h-32 rounded-full border-8 border-t-8 border-gray-200 ease-linear loader"></div>
      </div>
    );
  }

  const getCarrier = (segment) => {
    let code = flights.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes.Carrier;
    return getCarrierInfo(code)?.Name || code;
    // return CarrierList.find((carrier) => carrier.Code === code)?.Name || code;
  };

  const getAirSegment = (segment) => {
    return flights.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes;
  };

  const getLogo = (segment) => {
    let code = flights?.find(
      (flight) => flight._attributes.Key === segment._attributes.Key
    )._attributes.Carrier;
    return getCarrierLogo(code);
    // return CarrierList.find((carrier) => carrier.Code === code)?.Logo;
  };

  const calculateTime = (departure, arrival) => {
    let time = moment(departure).diff(moment(arrival), "minutes");

    let hours = Math.floor(time / 60);
    let minutes = time % 60;

    return `${hours}h ${minutes}m`;
  };

  const getCabinClass = (segment) => {
    return flight?.AirPricingInfo?.BookingInfo?.find(
      (flight) => flight._attributes.SegmentRef === segment._attributes.Key
    )._attributes.CabinClass;
  };

  // function disableFlight() {
  //   let origin = getAirSegment(
  //     flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
  //       flight.Journey?.[indexFlight]?.AirSegmentRef
  //   )?.Origin;
  //   let carrier = getAirSegment(
  //     flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
  //       flight.Journey?.[indexFlight]?.AirSegmentRef
  //   )?.Carrier;

  //   console.log(origin, carrier);

  //   if (!Locals.includes(origin) && (carrier === "TK" || carrier === "EK")) {
  //     return true;
  //   }

  //   if (passengerTypeState === NON_DOCUMENT_HOLDER && carrier !== "ET") {
  //     return true;
  //   }
  //   return false;
  // }

  function disableFlight() {
    let origin = getAirSegment(
      flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
        flight.Journey?.[indexFlight]?.AirSegmentRef
    )?.Origin;

    let carrier = getAirSegment(
      flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
        flight.Journey?.[indexFlight]?.AirSegmentRef
    )?.Carrier;

    if (
      !Locals.includes(origin) &&
      (carrier === "TK" ||
        carrier === "EK" ||
        carrier === "QR" ||
        carrier === "MS")
    ) {
      return true;
    }

    if (
      !Locals.includes(origin) &&
      currencyStates !== "USD" &&
      (carrier === "SV" || carrier === "KQ")
    ) {
      return true;
    }

    if (
      carrier === "ET" &&
      currencyStates === "USD" &&
      passengerTypeState !== NON_DOCUMENT_HOLDER
    ) {
      return true;
    }

    if (carrier === "XY" || carrier === "GF") {
      return true;
    }

    if (
      passengerTypeState === NON_DOCUMENT_HOLDER &&
      // carrier !== "ET" &&
      currencyStates !== "USD"
    ) {
      return true;
    }

    return false;
  }

  const selectBtn = (price, carrier) => {
    return (
      <div className="flex flex-col px-6 py-2 mt-auto">
        <p className="p-2 text-xs text-red-600">
          {showUSDInstruction(
            getAirSegment(
              flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
                flight.Journey?.[indexFlight]?.AirSegmentRef
            )?.Carrier,
            getAirSegment(
              flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
                flight.Journey?.[indexFlight]?.AirSegmentRef
            )?.Origin,
            currencyStates,
            passengerTypeState
          )}
        </p>
        {/* <Link to="/customer-detail"> */}
        <ButtonPrimary
          disabled={disableFlight()}
          className={`${
            disableFlight() &&
            "bg-gray-500 cursor-not-allowed hover:bg-gray-500"
          }`}
          onClick={() => {
            setIsReturn(true);
            setIsOpen(false);
            setTotalPrice(price);
            setDepartData({
              segment: flight.Journey[0].AirSegmentRef,
              classType:
                flight?.AirPricingInfo?.BookingInfo ||
                flight?.AirPricingInfo?.[0]?.BookingInfo,
            });
          }}
        >
          Select
        </ButtonPrimary>
        {/* </Link> */}
      </div>
    );
  };

  const selectBtnLink = (price, seg) => {
    return (
      <div className="flex flex-col px-6 py-2 mt-auto">
        <p className="p-2 text-xs text-red-600">
          {showUSDInstruction(
            getAirSegment(
              flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
                flight.Journey?.[indexFlight]?.AirSegmentRef
            )?.Carrier,
            getAirSegment(
              flight.Journey?.[indexFlight]?.AirSegmentRef?.[0] ||
                flight.Journey?.[indexFlight]?.AirSegmentRef
            )?.Origin,
            currencyStates,
            passengerTypeState
          )}
        </p>
        <Link to="/customer-detail">
          <ButtonPrimary
            className=""
            onClick={() => {
              setIsReturn(true);
              setIsOpen(false);
              setTotalPrice(price);
            }}
          >
            Select
          </ButtonPrimary>
        </Link>
      </div>
    );
  };

  function totalPrice(price) {
    const airPrice = parseFloat(price?.toString().slice(3));

    let result =
      rateDifference > 1 ? (airPrice * rateDifference).toFixed(2) : airPrice;

    result = parseFloat(result) + charge;
    return convertNumbThousand(result);
  }

  const renderDetailTop = (seg, stat, btnVisible, index) => {
    return (
      <div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-shrink-0 w-24 md:w-20 lg:w-24 md:pt-7">
            <img
              src={getLogo(
                flight.Journey[indexFlight].AirSegmentRef instanceof Array
                  ? flight.Journey[indexFlight].AirSegmentRef[indexFlight]
                  : flight.Journey[indexFlight].AirSegmentRef
              )}
              className="w-15"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0 lg:w-72">
            <div className="flex flex-col flex-shrink-0 items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow my-1 border-l border-dashed border-neutral-400"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              {stat ? (
                <>
                  <div className="flex flex-col space-y-1">
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 12 · 10:00 */}
                      <>{customTime(getAirSegment(seg).DepartureTime)}</>
                    </span>
                    <span className="font-semibold text-black">
                      {getAirSegment(seg).Origin}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {/* Monday, August 16 · 10:00 */}
                      {customTime(getAirSegment(seg).ArrivalTime)}
                    </span>
                    <span className="font-semibold text-black">
                      {getAirSegment(seg).Destination}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div className="flex flex-col space-y-1">
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {customTime(getAirSegment(seg).DepartureTime)}
                    </span>
                    <span className="font-semibold text-black">
                      {getAirSegment(seg).Origin}
                    </span>
                  </div>
                  <div className="flex flex-col space-y-1">
                    <span className="text-neutral-500 dark:text-neutral-400">
                      {customTime(getAirSegment(seg).ArrivalTime)}
                    </span>
                    <span className="font-semibold text-black">
                      {/* {getCarrier(seg)}  */}
                      {getAirSegment(seg).Destination}
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          {stat ? (
            <ul className="space-y-1 text-sm text-neutral-500 dark:text-neutral-400 md:space-y-2">
              <li>
                Flight time:{" "}
                <span>
                  {calculateTime(
                    getAirSegment(seg).ArrivalTime,
                    getAirSegment(seg).DepartureTime
                  )}
                </span>
              </li>
              <li>Flight Class: {getCabinClass(seg)}</li>
              <li>Flight Number: {getAirSegment(seg).FlightNumber}</li>
              <li>Equipment: {getAirSegment(seg).Equipment}</li>
              <li>
                Booking Class:{" "}
                {flight?.AirPricingInfo?.[index]?.BookingInfo?.[index]
                  ?._attributes?.BookingCode ||
                  flight?.AirPricingInfo?.BookingInfo?.[index]?._attributes
                    ?.BookingCode}
              </li>
            </ul>
          ) : (
            <ul className="space-y-1 text-sm text-neutral-500 dark:text-neutral-400 md:space-y-2">
              <li>
                Trip time:{" "}
                <span>
                  {calculateTime(
                    getAirSegment(seg).ArrivalTime,
                    getAirSegment(seg).DepartureTime
                  )}
                </span>
              </li>
              <li>Flight: {getAirSegment(seg).FlightNumber} </li>
              <li>Equipment: {getAirSegment(seg).Equipment}</li>
              <li>
                Flight Class: {getCabinClass(seg)}
                {/* {flight.AirPricingInfo.BookingInfo._attributes.CabinClass} */}
              </li>
              <li>
                Booking Class:{" "}
                {flight?.AirPricingInfo?.[0]?.BookingInfo?.[indexFlight]
                  ?._attributes?.BookingCode ||
                  flight?.AirPricingInfo?.BookingInfo?.[indexFlight]
                    ?._attributes?.BookingCode}
              </li>
              {/* <li>ANA · Business class · Boeing 787 · NH 847</li> */}
            </ul>
          )}
          {stat ? (
            btnVisible && (
              <>
                <div className="mx-auto"></div>
                {isReturn
                  ? selectBtnLink(flight?._attributes?.TotalPrice, seg)
                  : selectBtn(
                      flight?._attributes?.TotalPrice,
                      getAirSegment(seg)?.Carrier
                    )}
              </>
            )
          ) : (
            <>
              <div className="mx-auto"></div>
              {isReturn
                ? selectBtnLink(flight?._attributes?.TotalPrice, seg)
                : selectBtn(
                    flight?._attributes?.TotalPrice,
                    getAirSegment(seg)?.Carrier
                  )}
            </>
          )}
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 rounded-2xl border md:p-8 border-neutral-200 dark:border-neutral-700">
        {flight.Journey[indexFlight].AirSegmentRef instanceof Array ? (
          flight.Journey[indexFlight].AirSegmentRef.map((seg, index) => (
            <>
              {renderDetailTop(
                seg,
                true,
                index === flight.Journey[indexFlight].AirSegmentRef.length - 1,
                index
              )}
              {index ===
              flight.Journey[indexFlight].AirSegmentRef.length - 1 ? null : (
                <div className="my-7 space-y-5 md:my-10 md:pl-24">
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                  <div className="text-sm text-neutral-700 dark:text-neutral-300 md:text-base">
                    Transit time:{" "}
                    {calculateTime(
                      getAirSegment(
                        flight.Journey[indexFlight].AirSegmentRef[index + 1]
                      ).DepartureTime,
                      getAirSegment(
                        flight.Journey[indexFlight].AirSegmentRef[index]
                      ).ArrivalTime
                    )}
                  </div>
                  <div className="border-t border-neutral-200 dark:border-neutral-700" />
                </div>
              )}
            </>
          ))
        ) : (
          <>
            {renderDetailTop(flight.Journey[indexFlight].AirSegmentRef, false)}
          </>
        )}

        {/* {flight.Journey[indexFlight].AirSegmentRef instanceof Array && (
          <>
            <div className="my-7 space-y-5 md:my-10 md:pl-24">
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
              <div className="text-sm text-neutral-700 dark:text-neutral-300 md:text-base">
                Transit time: 15 hours 45 minutes - Bangkok (BKK)
              </div>
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
            </div>
            {renderDetailTop(true)}
          </>
        )} */}
      </div>
    );
  };

  return (
    <div
      className={`overflow-hidden relative p-4 space-y-6 bg-white rounded-2xl shadow transition-shadow nc-FlightCardgroup sm:p-6 dark:bg-neutral-900 border-1 border-neutral-300 md:shadow-none hover:shadow-lg ${className}`}
      data-nc-id="FlightCard"
      onClick={() => {
        setIsOpen(!isOpen);
        localStorage.setItem("pairedRefs", JSON.stringify([]));
      }}
    >
      <div
        className={`relative  sm:pr-20 ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          // onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex flex-col space-y-6 sm:flex-row sm:items-center sm:space-y-0">
          {/* LOGO IMG */}
          <div className="flex-shrink-0 w-24 lg:w-32">
            <img
              src={getLogo(
                flight.Journey[indexFlight]?.AirSegmentRef instanceof Array
                  ? flight?.Journey[indexFlight]?.AirSegmentRef[indexFlight]
                  : flight?.Journey[indexFlight]?.AirSegmentRef
              )}
              className="w-16"
              alt=""
            />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block space-y-1 text-black lg:hidden">
            <div className="flex font-semibold">
              <div>
                <span>
                  {customTime(
                    getAirSegment(
                      flight.Journey[indexFlight].AirSegmentRef instanceof Array
                        ? flight.Journey[indexFlight].AirSegmentRef[indexFlight]
                        : flight.Journey[indexFlight].AirSegmentRef
                    ).DepartureTime
                  )}{" "}
                </span>

                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {
                    getAirSegment(
                      flight.Journey[indexFlight].AirSegmentRef instanceof Array
                        ? flight.Journey[indexFlight].AirSegmentRef[indexFlight]
                        : flight.Journey[indexFlight].AirSegmentRef
                    ).Origin
                  }
                </span>
              </div>
              <span className="flex justify-center w-12">
                <i className="text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>
                  <span>
                    {customTime(
                      getAirSegment(
                        flight.Journey[indexFlight].AirSegmentRef instanceof
                          Array
                          ? flight.Journey[indexFlight].AirSegmentRef[
                              flight.Journey[indexFlight].AirSegmentRef.length -
                                1
                            ]
                          : flight.Journey[indexFlight].AirSegmentRef
                      ).ArrivalTime
                    )}{" "}
                  </span>
                </span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {
                    getAirSegment(
                      flight.Journey[indexFlight].AirSegmentRef instanceof Array
                        ? flight.Journey[indexFlight].AirSegmentRef[
                            flight.Journey[indexFlight].AirSegmentRef.length - 1
                          ]
                        : flight.Journey[indexFlight].AirSegmentRef
                    ).Destination
                  }
                </span>
              </div>
              <div className="w-24"></div>
              <div className="text-sm text-black font-normal mt-0.5">
                <span className="VG3hNb">
                  {flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef.length -
                      1 +
                      " Stops"
                    : "Direct"}
                </span>
              </div>
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="text-lg font-medium text-black">
              {customTime(
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef[indexFlight]
                    : flight.Journey[indexFlight].AirSegmentRef
                ).DepartureTime
              ) +
                " - " +
                customTime(
                  getAirSegment(
                    flight.Journey[indexFlight].AirSegmentRef instanceof Array
                      ? flight.Journey[indexFlight].AirSegmentRef[
                          flight.Journey[indexFlight].AirSegmentRef.length - 1
                        ]
                      : flight.Journey[indexFlight].AirSegmentRef
                  ).ArrivalTime
                )}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* {data.airlines.name} */}
              {getCarrier(
                flight.Journey[indexFlight].AirSegmentRef instanceof Array
                  ? flight.Journey[indexFlight].AirSegmentRef[indexFlight]
                  : flight.Journey[indexFlight].AirSegmentRef
              )}
            </div>
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="text-lg font-medium text-black">
              {
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef[0]
                    : flight.Journey[indexFlight].AirSegmentRef
                ).Origin
              }{" "}
              -{" "}
              {
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef[
                        flight.Journey[indexFlight].AirSegmentRef.length - 1
                      ]
                    : flight.Journey[indexFlight].AirSegmentRef
                ).Destination
              }
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {moment(
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[0].AirSegmentRef[
                        flight.Journey[indexFlight].AirSegmentRef.length - 1
                      ]
                    : flight.Journey[0].AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[0].AirSegmentRef[0]
                    : flight.Journey[0].AirSegmentRef
                ).DepartureTime,
                "hours"
              )}{" "}
              hours{" "}
              {moment(
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef[
                        flight.Journey[indexFlight].AirSegmentRef.length - 1
                      ]
                    : flight.Journey[indexFlight].AirSegmentRef
                ).ArrivalTime
              ).diff(
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef[0]
                    : flight.Journey[indexFlight].AirSegmentRef
                )?.DepartureTime,
                "minutes"
              ) % 60}{" "}
              minutes
            </div>
          </div>

          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="text-lg font-medium text-black">
              {flight.Journey[indexFlight].AirSegmentRef instanceof Array
                ? flight.Journey[indexFlight].AirSegmentRef.length -
                  1 +
                  " Stops " +
                  `(${
                    getAirSegment(
                      flight.Journey[indexFlight]?.AirSegmentRef?.[0]
                    ).Destination +
                    (flight.Journey[indexFlight].AirSegmentRef.length - 1 > 1
                      ? ", " +
                        getAirSegment(
                          flight.Journey[indexFlight]?.AirSegmentRef?.[1]
                        ).Destination
                      : "")
                  })`
                : "Direct"}
            </div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {/* calculate total stop time */}

              {/* 2 hours 15 minutes BKK || Unknown yet */}
            </div>
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {totalPrice(flight._attributes.TotalPrice)}{" "}
                {/* {convertToNumber(flight._attributes.TotalPrice)}{" "} */}
                {flight._attributes.TotalPrice.substring(0, 3)}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              {/* <p>Round-trip</p> */}
              Flight-{" "}
              {
                getAirSegment(
                  flight.Journey[indexFlight].AirSegmentRef instanceof Array
                    ? flight.Journey[indexFlight].AirSegmentRef[indexFlight]
                    : flight.Journey[indexFlight].AirSegmentRef
                ).FlightNumber
              }
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default RoundTrip;
