import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";

const NDCConfirmModal = ({
  title,
  message,
  error,
  setError,
  setLoading,
  loading,
  handleCancel,
}) => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="text-center w-[500px] p-10 bg-white shadow-lg text-black">
          <h1 className="p-2 text-2xl font-bold text-red-500">{title}</h1>
          <p className="text-lg">{message}</p>
          <button
            onClick={onClose}
            className="w-40 border-1 cursor-pointer text-black text-lg m-2.5 p-2.5 border-black"
          >
            No
          </button>
          <button
            onClick={async () => {
              onClose();
              const data = {
                bookingId: error?.data?.bookingId,
                PNR: error?.data?.flight?.retrievalPnr,
              };
              const response = await handleCancel(data, setLoading, setError);

              if (response?.Fault) {
                toast.error(response?.Fault?.faultstring?._text);
              }

              if (response?.UniversalRecordCancelRsp) {
                toast.success("Booking cancelled successfully");
              }
            }}
            disabled={loading}
            className="w-40 border-1 cursor-pointer text-red-500 text-lg m-2.5 p-2.5 border-red-500 font-bold"
          >
            {loading ? "Cancelling..." : "Yes, Cancel it!"}
          </button>
        </div>
      );
    },
  });
};

export default NDCConfirmModal;
