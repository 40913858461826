import moment from "moment";

// const getUSDCurrencyRate = async () => {
//   try {
//     const response = await axios.get(
//       "https://www.combanketh.et/cbeapi/daily-exchange-rates?_limit=1&_sort=Date%3ADESC"
//     );
//     let rate = response.data[0]?.ExchangeRate.filter(
//       (rate) => rate.currency?.CurrencyCode === "USD"
//     );

//     return rate[0];
//   } catch (error) {
//     console.error("Error fetching USD currency", error);
//   }
// };

const isCreditTimeRestricted = () => {
  const now = moment();

  const start = moment().set({ hour: 22, minute: 45, second: 0 });
  const end = moment().set({ hour: 23, minute: 59, second: 59 });
  // const start = moment().set({ hour: 20, minute: 0, second: 0 });
  // const end = moment().set({ hour: 21, minute: 59, second: 59 });

  return now.isBetween(start, end, null, "[]");
};

const checkVoidTime = async (flight) => {
  const departureTime = moment(flight?.departureTime);
  const now = moment();
  const timeDiff = departureTime.diff(now, "hours");

  const isBefore4Hours = timeDiff < 4;

  return isBefore4Hours;
};

export { isCreditTimeRestricted, checkVoidTime };
