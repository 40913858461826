import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { NDCTicketInfo } from "./component/NDCTicketInfo";

const NDCTicketPrint = () => {
  const componentRef = useRef();
  const printWrapperRef = useRef();

  const [selectedPassenger, setSelectedPassenger] = useState("all");

  const location = useLocation();
  let { ticketData, passengerInfo, companyInfo } = location.state;

  // if (ticketData?.AirReservation?.DocumentInfo?.TicketInfo instanceof Array) {
  //   ticketData.AirReservation.DocumentInfo.TicketInfo =
  //     ticketData?.AirReservation?.DocumentInfo?.TicketInfo?.filter((ticket) => {
  //       return (
  //         ticket?._attributes?.Status === "N" ||
  //         ticket?._attributes?.Status === "R"
  //       );
  //     });
  // }
  // let activeTicket = ticketData?.AirReservation?.DocumentInfo?.TicketInfo;

  // passengerInfo = passengerInfo.filter((passenger) => {
  //   return passenger?.status === "pending" || passenger?.status === "approved";
  // });

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });

  // let passengerList = [];
  // let passengerNames = [];
  // if (ticketData?.BookingTraveler instanceof Array) {
  //   passengerList = ticketData?.BookingTraveler?.map((passenger) => {
  //     if (activeTicket) {
  //       const isPassengerIncluded = activeTicket?.some(
  //         (ticket) =>
  //           ticket?._attributes?.BookingTravelerRef ===
  //           passenger?._attributes?.Key
  //       );
  //       if (isPassengerIncluded) {
  //         passengerNames.push
  //           ? passengerNames.push(passenger?.BookingTravelerName?._attributes)
  //           : passengerNames.push(passenger?.BookingTravelerName?._attributes);

  //         return [
  //           passenger?.BookingTravelerName?._attributes,
  //           passenger?.AirSeatAssignment,
  //         ];
  //       }
  //     } else {
  //       return [
  //         passenger?.BookingTravelerName?._attributes,
  //         passenger?.AirSeatAssignment,
  //       ];
  //     }
  //   });
  // } else {
  //   passengerList.push([
  //     ticketData?.BookingTraveler?.BookingTravelerName?._attributes,
  //     ticketData?.BookingTraveler?.AirSeatAssignment,
  //   ]);
  //   passengerNames.push(
  //     ticketData?.BookingTraveler?.BookingTravelerName?._attributes
  //   );
  // }

  // let passportInfo = passengerInfo;
  // if (selectedPassenger !== "all") {
  //   passengerList = passengerList.filter((passenger, index) => {
  //     return (
  //       passenger?.[0]?.First?.trim() + " " + passenger?.[0]?.Last.trim() ===
  //       selectedPassenger
  //     );
  //   });
  //   ticketNumbers =
  //     ticketData?.AirReservation?.DocumentInfo?.TicketInfo?.filter((ticket) => {
  //       return (
  //         ticket?.Name?._attributes?.First.trim() +
  //           " " +
  //           ticket?.Name?._attributes?.Last.trim() ===
  //         selectedPassenger
  //       );
  //     })?.[0]?._attributes?.Number;
  //   ticketNumbers = [ticketNumbers];

  //   passportInfo = passengerInfo.filter((passenger) => {
  //     return (
  //       passenger?.firstName.trim() + " " + passenger?.lastName.trim() ===
  //       selectedPassenger
  //     );
  //   });
  // }

  // passengerList = passengerList.filter((passenger) => passenger !== undefined);

  return (
    <>
      <div className="my-4 xl:mx-80">
        {/* {passengerNames?.length > 1 && (
          <select
            className="mx-10 block border-1 text-black text-[15px] border-black w-1/4 rounded-lg  bg-white p-1"
            onChange={(e) => setSelectedPassenger(e.target.value)}
          >
            <option value="all">All</option>
            {passengerNames?.map((passenger, index) => (
              <option key={index}>
                <h3>
                  {passenger?.First + " " + passenger?.Last}
                </h3>
              </option>
            ))}
          </select>
        )} */}
        <div ref={printWrapperRef}>
          <div style={{ pageBreakAfter: "always" }}>
            <NDCTicketInfo
              ticketData={ticketData}
              // passengerList={passengerList}
              ref={componentRef}
              companyInfo={companyInfo?._id}
            />
          </div>
          {/* <div className="mx-10">
            <h1 className="text-xl font-bold underline">SSR</h1>
            {ticketData?.BookingTraveler?.length > 0 ? (
              ticketData?.BookingTraveler?.map((data, index) => (
                <div className="flex flex-col space-y-1" key={index}>
                  <h1 className="text-lg font-bold">Passenger {++index}</h1>
                  {data?.SSR?.length > 0
                    ? data?.SSR?.map((ssr, i) => (
                        <div
                          key={i}
                          className="flex flex-row gap-5 px-2 py-1 mx-3 rounded-xl"
                        >
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(ssr?._attributes?.Type)} (
                            {ssr?._attributes?.Type})
                          </h1>
                          <h1 className="px-2 w-1/4 text-gray-500">
                            {SSRStatus(ssr?._attributes?.Status)} (
                            {ssr?._attributes?.Status})
                          </h1>
                          {ticketData?.AirReservation?.AirSegment.length && (
                            <h1 className="px-2 text-gray-500">
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Origin
                              }
                              {" - "}
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Destination
                              }
                            </h1>
                          )}
                        </div>
                      ))
                    : data?.SSR?._attributes && (
                        <div className="flex flex-row gap-5 px-2 py-1 mx-3 rounded-xl">
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(data?.SSR?._attributes?.Type)} (
                            {data?.SSR?._attributes?.Type})
                          </h1>
                          <h1 className="px-2 text-gray-500">
                            {SSRStatus(data?.SSR?._attributes?.Status)} (
                            {data?.SSR?._attributes?.Status})
                          </h1>
                        </div>
                      )}
                </div>
              ))
            ) : (
              <div className="">
                <div className="flex flex-col space-y-1">
                  {ticketData?.BookingTraveler?.SSR?.length > 0
                    ? ticketData?.BookingTraveler?.SSR?.map((ssr, i) => (
                        <div
                          key={i}
                          className="flex flex-row gap-5 px-2 py-1 mx-3 rounded-xl"
                        >
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(ssr?._attributes?.Type)} (
                            {ssr?._attributes?.Type})
                          </h1>
                          <h1 className="px-2 w-1/4 text-gray-500">
                            {SSRStatus(ssr?._attributes?.Status)} (
                            {ssr?._attributes?.Status})
                          </h1>
                          {ticketData?.AirReservation?.AirSegment.length && (
                            <h1 className="px-2 text-gray-500">
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Origin
                              }
                              {" - "}
                              {
                                getSegmentByKey(ssr?._attributes?.SegmentRef)
                                  ?.Destination
                              }
                            </h1>
                          )}
                        </div>
                      ))
                    : ticketData?.BookingTraveler?.SSR?._attributes && (
                        <div className="flex flex-row gap-5 px-2 py-1 mx-3 rounded-xl">
                          <h1 className="px-2 w-1/3">
                            {SSRTypes(
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Type
                            )}{" "}
                            (
                            {
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Type
                            }
                            )
                          </h1>
                          <h1 className="px-2 text-gray-500">
                            {SSRStatus(
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Status
                            )}{" "}
                            (
                            {
                              ticketData?.BookingTraveler?.SSR?._attributes
                                ?.Status
                            }
                            )
                          </h1>
                        </div>
                      )}
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
      <div className="flex gap-6 justify-center p-6 mt-10 space-x-6">
        <button
          className="p-3 text-black bg-white rounded-md transition duration-300 ease-in-out transform outline hover:bg-gray-100 hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
          onClick={handlePrint}
        >
          Download Ticket
        </button>
        <button
          className="p-3 text-black bg-white rounded-md transition duration-300 ease-in-out transform outline hover:bg-gray-100 hover:-translate-y-1 hover:scale-110 hover:shadow-xl focus:outline-none"
          onClick={handlePrint}
        >
          Print Ticket
        </button>
      </div>
    </>
  );
};

// function FlightDetail(fareInfo, segment, index) {
//   return (
//     <>
//       <h1 className="py-1 font-bold text-md">
//         Flight: {segment?.Origin} - {segment?.Destination} - Passenger (
//         {++index || 1})
//       </h1>
//       <div className="flex flex-row justify-between px-4">
//         <div>
//           <h2 className="font-semibold">Baggage Allowance</h2>
//           <div className="">
//             <div className="space-y-1">
//               {fareInfo?.BaggageAllowance?.NumberOfPieces ? (
//                 <h3>
//                   <span className="font-semibold">
//                     {fareInfo?.BaggageAllowance?.NumberOfPieces?._text}{" "}
//                   </span>
//                   Piece(s) Plan
//                 </h3>
//               ) : (
//                 <h3>
//                   <span className="font-semibold">
//                     {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Value}{" "}
//                   </span>
//                   {fareInfo?.BaggageAllowance?.MaxWeight?._attributes?.Unit}{" "}
//                   Plan
//                 </h3>
//               )}
//             </div>
//           </div>
//         </div>
//         <div>
//           <h2 className="font-semibold">Fare Basis:</h2>
//           <div className="">{fareInfo?._attributes?.FareBasis}</div>
//         </div>
//         <div>
//           <h2 className="font-semibold">Not Valid Before:</h2>
//           <h3>
//             {moment(fareInfo?._attributes?.NotValidBefore).format(
//               "MMM DD, YYYY"
//             )}
//           </h3>
//         </div>
//         <div>
//           <h2 className="font-semibold">Not Valid After:</h2>
//           <h3>
//             {" "}
//             {moment(fareInfo?._attributes?.NotValidAfter).format(
//               "MMM DD, YYYY"
//             )}
//           </h3>
//         </div>
//       </div>
//     </>
//   );
// }

export default NDCTicketPrint;
