import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import logo from "../../../../images/logo.png";
import Loading from "../../../Loading/index.jsx";
import { getCompanyById } from "../../../../redux2/user/userSlice.js";
import { getCarrierInfo, getFlightName } from "../../../../utils/flightInfo.js";
import NDCTicketDetail from "./NDCTicketDetail.jsx";
import "../style.css";

export const NDCTicketInfo = React.forwardRef(
  ({ ticketData, companyInfo }, ref) => {
    const dispatch = useDispatch();

    const { company, isLoading } = useSelector((state) => state.user);

    useEffect(() => {
      dispatch(getCompanyById(companyInfo));
    }, [dispatch, companyInfo]);

    const { Passenger } = ticketData?.Passengers;
    const { TicketDocInfo } = ticketData?.TicketDocInfos || {};
    const { Order } = ticketData;

    let carrierLogo = getCarrierInfo(Order[0]?.OrderID?.Owner)?.Logo;

    const flightSegment = Order[0]?.OrderItems?.OrderItem?.reduce(
      (segments, item) => {
        if (item?.FlightItem?.OriginDestination && segments.length === 0) {
          segments.push(item.FlightItem.OriginDestination);
        }
        return segments;
      },
      []
    );

    const destination =
      flightSegment?.[0]?.[0]?.Flight?.at(-1)?.Arrival?.AirportCode?.value;

    const flattenedFlights = flightSegment.flatMap((outerArray) =>
      outerArray.flatMap((flightObject) => flightObject.Flight)
    );

    const getSegment = (ref) => {
      return Order[0]?.OrderItems?.OrderItem?.flatMap(
        (item) => item?.FlightItem?.OriginDestination || []
      )
        .flatMap((originDest) => originDest?.Flight || [])
        .find((flight) => flight?.SegmentKey === ref);
    };

    const getPassenger = (ref) => {
      return Passenger?.find((passenger) => passenger?.ObjectKey === ref);
    };

    const baggageInfos = Order[0]?.OrderItems?.OrderItem?.flatMap(
      (item) => item?.BaggageItem?.refs || []
    );

    const getBaggageInfo = (ref) =>
      ticketData?.DataLists?.ServiceList?.Service?.find(
        (item) => item.ObjectKey === ref
      );

    const baggageInfo = baggageInfos.map(getBaggageInfo);

    const getBaggageAllowance = (ref) => {
      return (
        ticketData?.DataLists?.CheckedBagAllowanceList?.CheckedBagAllowance?.find(
          (item) => item.ListKey === ref
        ) ||
        ticketData?.DataLists?.CarryOnAllowanceList?.CarryOnAllowance?.find(
          (item) => item.ListKey === ref
        )
      );
    };

    if (isLoading) return <Loading />;

    return (
      <div className="mx-10 my-4 text-black ticket-print" ref={ref}>
        <div className="my-1 text-lg font-semibold flight-header">
          <h1>Trip to {getFlightName(destination)}</h1>
        </div>
        <hr />
        <div className="passenger-header">
          <div className="">
            <p>PREPARED FOR </p>
            <div className="flex flex-col">
              {Passenger?.map((passenger, index) => (
                <h1
                  className="text-xl font-normal tracking-wide text-gray-600"
                  key={index}
                >
                  {passenger?.Name?.Title && passenger?.Name?.Title + ". "}
                  <span className="font-semibold uppercase">
                    {passenger?.Name?.Given[0]?.value +
                      " " +
                      passenger?.Name?.Surname?.value}
                  </span>
                </h1>
              ))}
            </div>
            {/* <div className="flex flex-row gap-5 justify-between font-light tracking-wide text-gray-500 text-md">
              <p className="">
                Confirmation number{": "}
                <span className="font-bold">
                  {
                    airReservation?.SupplierLocator?._attributes
                      ?.SupplierLocatorCode
                  }
                </span>
              </p>
              <p className="">
                Reservation Code{": "}
                <span className="font-bold">
                  {reservation?._attributes?.LocatorCode}
                </span>
              </p>
            </div> */}
          </div>
          <div className="text-sm">
            <p>Passport Number</p>
            <div className="flex flex-col">
              {Passenger?.map((passenger, index) => (
                <h1
                  className="text-lg font-normal tracking-wide text-gray-600"
                  key={index}
                >
                  {passenger?.PassengerIDInfo?.PassengerDocument[0]?.ID}
                </h1>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center">
            <img
              src={carrierLogo}
              alt="logo"
              className="object-cover w-32 h-20"
            />
          </div>
        </div>
        <div className="line"></div>

        {flattenedFlights.map((segment, index) => {
          return (
            <NDCTicketDetail
              data={segment}
              passengerList={Passenger}
              TicketDocInfo={TicketDocInfo}
              key={index}
              index={index}
            />
          );
        })}

        <div className="line"></div>

        <div className="p-2 my-2 border-1 border-slate-400">
          <h1 className="py-2 font-semibold text-md text-grey-700">
            Baggage Information
          </h1>
          <div className="row">
            <p className="col-2 sm:px-10">Passenger</p>
            <p className="col-2 sm:px-10">Flight</p>
            <p className="col-4 sm:px-10">Type</p>
            <p className="col-4">Baggage Allowance</p>
          </div>
          {baggageInfo?.map((baggage, index) => {
            return (
              <FlightDetail
                baggage={baggage}
                getSegment={getSegment}
                getPassenger={getPassenger}
                getBaggageAllowance={getBaggageAllowance}
                key={index}
              />
            );
          })}
        </div>

        <div className="flex justify-between px-1 text-xs">
          {company ? (
            <>
              {/* <h1 className="text-xl font-bold">Booking Technologies PLC</h1> */}
              <h1 className="text-xl font-bold">{company?.name}</h1>
              <div className="text-xs">
                <div className="space-y-1">
                  <h3>Head Office: {company?.address} </h3>
                  <h3 className="text-center sm:ml-3">
                    {/* Addis Ababa, Ethiopia */}
                    Ethiopia
                  </h3>
                </div>
              </div>
              <div>
                <h2>Agency Office Phone:</h2>
                <h3>{company?.officeNumber}</h3>
                <h3>{company?.officeNumber2}</h3>
              </div>
            </>
          ) : (
            <>
              <img
                src={logo}
                alt=""
                style={{ width: "150px", height: "38px" }}
              />
              <div className="text-xs">
                <div className="space-y-1">
                  <h3>Head Office: Near the National Theater </h3>
                  <h3 className="text-center sm:ml-14">
                    Addis Ababa, Ethiopia
                  </h3>
                </div>
              </div>
              <div>
                <h2>Agency Office Phone:</h2>
                <h3>+251 115 511333 </h3>
                <h3>+251 115 514666</h3>
              </div>
              <div>
                <h2>Agency Mobile Phone:</h2>
                <h3>+251 922 238193</h3>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

function FlightDetail({
  baggage,
  getSegment,
  getPassenger,
  getBaggageAllowance,
}) {
  const segment = getSegment(
    baggage?.Associations?.[0]?.Flight
      ?.originDestinationReferencesOrSegmentReferences[0]
  );

  const passenger = getPassenger(
    baggage?.Associations?.[0]?.Traveler?.TravelerReferences[0]
  );

  const baggageAllowance = getBaggageAllowance(baggage?.refs[0]);

  return (
    <div className="row sm:mx-2">
      <h1 className="col-2">{passenger?.Name?.Given[0]?.value}</h1>
      <h1 className="col-2">
        {segment?.Departure?.AirportCode?.value} -{" "}
        {segment?.Arrival?.AirportCode?.value}
      </h1>
      <h1 className="col-4">
        {baggage?.Name?.value.split(" ")[0] === "BAG:Carry"
          ? "Carry On"
          : "Checked"}{" "}
        Allowance
      </h1>
      <div className="col-4">
        {baggageAllowance?.PieceAllowance ? (
          <h1>
            <span className="font-semibold text-grey-700">
              {baggageAllowance?.PieceAllowance?.[0]?.TotalQuantity}{" "}
            </span>
            Piece(s){" "}
            {baggageAllowance?.WeightAllowance?.MaximumWeight[0]?.Value}{" "}
            {baggageAllowance?.WeightAllowance?.MaximumWeight[0]?.UOM} Plan
          </h1>
        ) : (
          <h1>
            <span className="font-semibold text-grey-700">
              {baggageAllowance?.WeightAllowance?.MaximumWeight[0]?.Value}{" "}
            </span>
            {baggageAllowance?.WeightAllowance?.MaximumWeight[0]?.UOM} Plan
          </h1>
        )}
      </div>
    </div>
  );
}
