import React from "react";
import { Dropdown } from "react-bootstrap";
import CreditSettlement from "./CreditSettlement";
import USDCreditSettlement from "./USDCreditSettlement";
// import AddManualBillingModal from "../Modals/AddManualBillingModal";
import ShortTermCreditSettlement from "./ShortTermCreditSettlement";

const BillingActions = ({
  isSuper,
  setOpenAddWallet,
  setOpenAddLimit,
  setOpenServiceCharge,
  handleExport,
  billing,
  id,
  credit,
  openManualBilling,
  setOpenManualBilling,
  handleManualBilling,
}) => {
  return (
    <div className="flex flex-col justify-between items-start md:flex-row md:items-center">
      {isSuper && (
        <div className="flex flex-col mx-2 md:flex-row md:gap-4">
          <div className="inline-block">
            <div
              className="mb-4 text-white btn bg-main btn-sm mb-xxl-0 hover:bg-main-800"
              onClick={() => setOpenAddWallet(true)}
            >
              + Add To Wallet
            </div>
          </div>
          <div className="inline-block">
            <div
              className="mb-4 text-white btn bg-main btn-sm mb-xxl-0 hover:bg-main-800"
              onClick={() => setOpenAddLimit(true)}
              // onClick={() => setOpenAddLimit(false)}
              disabled
            >
              Update Credit Limit
            </div>
          </div>
          <div className="inline-block">
            <div
              className="mb-4 text-white btn bg-main btn-sm mb-xxl-0 hover:bg-main-800"
              onClick={() => setOpenServiceCharge(true)}
              disabled
            >
              Update Service Charge
            </div>
          </div>
        </div>
      )}

      <div className="flex flex-row">
        {isSuper && (
          <Dropdown className="mx-auto dropdown text-sans-serif">
            <Dropdown.Toggle
              // id="dropdown"
              variant=""
              className="p-2 px-4 mx-3 text-white border-main-100 border-1"
              type="button"
              id="order-dropdown-0"
              data-toggle="dropdown"
              data-boundary="viewport"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="flex">
                <h1 className="mx-2">Action</h1>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="18px"
                    height="18px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth={1}
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x={0} y={0} width={24} height={24} />
                      <circle fill="#000000" cx={5} cy={12} r={2} />
                      <circle fill="#000000" cx={12} cy={12} r={2} />
                      <circle fill="#000000" cx={19} cy={12} r={2} />
                    </g>
                  </svg>
                </span>
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="py-0 border dropdown-menu dropdown-menu-right"
              aria-labelledby="order-dropdown-0"
              style={{ minWidth: "20rem" }}
            >
              <div className="px-4 mx-2 mt-2 text-lg font-medium text-black">
                {billing?.company?.creditType !== "none" && (
                  <div className="py-2 hover:text-main">
                    <CreditSettlement
                      billing={billing}
                      id={id}
                      credit={credit}
                    />
                  </div>
                )}

                <div className="py-2 hover:text-main">
                  <USDCreditSettlement billing={billing} id={id} />
                </div>

                {/* <div className="py-2 hover:text-main">
                  <AddManualBillingModal
                    openManualBilling={openManualBilling}
                    setOpenManualBilling={setOpenManualBilling}
                    handleManualBilling={handleManualBilling}
                  />
                </div> */}

                <div className="py-2 hover:text-main">
                  {/* <CreditPayment company={company} /> */}
                  <ShortTermCreditSettlement
                    billing={billing}
                    id={id}
                    credit={credit}
                  />
                </div>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
        <div className="mx-auto">
          <button
            className="btn bg-[#EA9C2A] hover:bg-800 text-white border-main-100 mb-xxl-0 mb-4 hover:bg-main-800"
            onClick={handleExport}
          >
            <i className="far fa-file-word me-2"></i>Generate Report
          </button>
        </div>
      </div>
    </div>
  );
};

export default BillingActions;
