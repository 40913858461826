import React from "react";
import { LoadingButton } from "@mui/lab";
import { Button, Switch, TextField } from "@mui/material";
import { Formik } from "formik";

import { Modal } from "react-bootstrap";

import { toast } from "react-toastify";

const UpdateServiceChargeModal = ({
  openServiceCharge,
  setOpenServiceCharge,
  handleServiceCharge,
  initialValues,
  pricingRules,
  columnsCount,
  NDCServiceCharges,
  handleChangeCharge,
}) => {
  return (
    <Modal
      className="modal fade"
      show={openServiceCharge}
      onHide={setOpenServiceCharge}
      size="md"
    >
      <div className="" role="document">
        <div className="">
          <div className="modal-header">
            <h4 className="modal-title fs-20">Update Service Charge</h4>
            <button
              type="button"
              className="btn-close"
              onClick={() => setOpenServiceCharge(false)}
              data-dismiss="modal"
            ></button>
          </div>
          <div className="modal-body">
            <i className="text-black flat-icon-cancel-12 close"></i>
            <div className="add-contact-box">
              <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                  // console.log(values);
                  const transformedPricingRules = Array.from(
                    { length: pricingRules.length },
                    (_, index) => ({
                      minPrice: pricingRules[`${index}`].minPrice,
                      maxPrice: pricingRules[`${index}`].maxPrice,
                      charge: values[`charge_${index}`],
                    })
                  );

                  // const transformedBusinessPricingRules = Array.from(
                  //   { length: businessPricingRules.length },
                  //   (_, index) => ({
                  //     minPrice: values[`businessMinPrice_${index}`],
                  //     maxPrice: values[`businessMaxPrice_${index}`],
                  //     charge: values[`businessCharge_${index}`],
                  //   })
                  // );

                  if (
                    NDCServiceCharges.length === 0 ||
                    NDCServiceCharges.some(
                      (value, index, self) =>
                        self.findIndex((t) => t.carrier === value.carrier) !==
                        index
                    )
                  ) {
                    toast.error("Invalid / Duplicated NDC Service Charges");
                    return;
                  }

                  const data = {
                    ...values,
                    international: transformedPricingRules,
                    serviceChargeLabor: values.isLabor
                      ? values.serviceChargeLabor
                      : 0,
                    serviceChargeOneWay: values.isOneWay
                      ? values.serviceChargeOneWay
                      : 0,
                    exchangeLocalSC: values.exchangeLocalSC,
                    exchangeInternationalSC: values.exchangeInternationalSC,
                    NDCServiceCharge: NDCServiceCharges,
                    // internationalBusiness: ,
                  };
                  handleServiceCharge(data);
                }}
                // validationSchema={EmployeeSchema}
              >
                {({ values, handleChange, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className="flex flex-col justify-center">
                      <div className="row">
                        <div className="mb-8">
                          <TextField
                            fullWidth
                            id="local"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="local"
                            placeholder="Local Service Charge"
                            label="Local Service Charge (in Birr)"
                            // disabled={checker?.data?.user?.email ?? false}
                            value={values.local}
                            onChange={handleChange}
                            // error={touched.email && Boolean(errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                        </div>
                        <div>
                          <label htmlFor="">
                            International Economy Service Charge
                          </label>
                          <ul className="mb-4 col-md-12">
                            <div className="row">
                              {[...Array(columnsCount)].map((_, colIndex) => (
                                <div key={colIndex} className="col-md-6">
                                  {pricingRules
                                    .slice(
                                      colIndex *
                                        Math.ceil(
                                          pricingRules.length / columnsCount
                                        ),
                                      (colIndex + 1) *
                                        Math.ceil(
                                          pricingRules.length / columnsCount
                                        )
                                    )
                                    .map((rule, rowIndex) => (
                                      <li
                                        key={rowIndex}
                                        className="mb-4 col-md-12"
                                      >
                                        {/* Use hidden fields to store minPrice and maxPrice */}
                                        <input
                                          type="hidden"
                                          name={`minPrice_${
                                            colIndex *
                                              Math.ceil(
                                                pricingRules.length /
                                                  columnsCount
                                              ) +
                                            rowIndex
                                          }`}
                                          value={rule.minPrice}
                                        />
                                        <input
                                          type="hidden"
                                          name={`maxPrice_${
                                            colIndex *
                                              Math.ceil(
                                                pricingRules.length /
                                                  columnsCount
                                              ) +
                                            rowIndex
                                          }`}
                                          value={rule.maxPrice}
                                        />

                                        <TextField
                                          type="number"
                                          size="small"
                                          variant="outlined"
                                          className="w-full"
                                          label={`Charge for ${rule.minPrice} - ${rule.maxPrice}`}
                                          value={
                                            values[
                                              `charge_${
                                                colIndex *
                                                  Math.ceil(
                                                    pricingRules.length /
                                                      columnsCount
                                                  ) +
                                                rowIndex
                                              }`
                                            ]
                                          }
                                          onChange={handleChange}
                                          name={`charge_${
                                            colIndex *
                                              Math.ceil(
                                                pricingRules.length /
                                                  columnsCount
                                              ) +
                                            rowIndex
                                          }`}
                                        />
                                      </li>
                                    ))}
                                </div>
                              ))}
                            </div>
                          </ul>
                        </div>

                        {/* <div className="mb-8">
                        <label htmlFor="">
                          International Business Service Charge
                        </label>
                        <ul className="mb-4 col-md-12">
                          <div className="row">
                            {[...Array(columnsCount)].map((_, colIndex) => (
                              <div key={colIndex} className="col-md-6">
                                {businessPricingRules
                                  .slice(
                                    colIndex *
                                      Math.ceil(
                                        businessPricingRules.length /
                                          columnsCount
                                      ),
                                    (colIndex + 1) *
                                      Math.ceil(
                                        businessPricingRules.length /
                                          columnsCount
                                      )
                                  )
                                  .map((rule, rowIndex) => (
                                    <li
                                      key={rowIndex}
                                      className="mb-4 col-md-12"
                                    >
                                      <input
                                        type="hidden"
                                        name={`businessMinPrice_${
                                          colIndex *
                                            Math.ceil(
                                              businessPricingRules.length /
                                                columnsCount
                                            ) +
                                          rowIndex
                                        }`}
                                        value={rule.minPrice}
                                      />
                                      <input
                                        type="hidden"
                                        name={`businessMaxPrice_${
                                          colIndex *
                                            Math.ceil(
                                              businessPricingRules.length /
                                                columnsCount
                                            ) +
                                          rowIndex
                                        }`}
                                        value={rule.maxPrice}
                                      />

                                      <TextField
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className="w-full"
                                        label={`Charge for ${rule.minPrice} - ${rule.maxPrice}`}
                                        value={
                                          values[
                                            `businessCharge_${
                                              colIndex *
                                                Math.ceil(
                                                  businessPricingRules.length /
                                                    columnsCount
                                                ) +
                                              rowIndex
                                            }`
                                          ]
                                        }
                                        onChange={handleChange}
                                        name={`businessCharge_${
                                          colIndex *
                                            Math.ceil(
                                              businessPricingRules.length /
                                                columnsCount
                                            ) +
                                          rowIndex
                                        }`}
                                      />
                                    </li>
                                  ))}
                              </div>
                            ))}
                          </div>
                        </ul>
                      </div> */}

                        <div className="mb-8">
                          <TextField
                            fullWidth
                            id="internationalBusiness"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="internationalBusiness"
                            placeholder="International Business Service Charge"
                            label="International Business Service Charge (in Dollars)"
                            value={values.internationalBusiness}
                            onChange={handleChange}
                            // error={touched.email && Boolean(errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                        </div>

                        <div className="col-md-6">
                          <TextField
                            fullWidth
                            id="exchangeLocalSC"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="exchangeLocalSC"
                            placeholder="Exchange Local SC"
                            label="Exchange Local SC (in Birr)"
                            value={values.exchangeLocalSC}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="col-md-6">
                          <TextField
                            fullWidth
                            id="exchangeInternationalSC"
                            type="number"
                            size="small"
                            variant="outlined"
                            name="exchangeInternationalSC"
                            placeholder="International Exchange SC"
                            label="International Exchange SC (in Birr)"
                            value={values.exchangeInternationalSC}
                            onChange={handleChange}
                            // error={touched.email && Boolean(errors.email)}
                            // helperText={touched.email && errors.email}
                          />
                        </div>

                        <div className="my-2 col-md-6">
                          <Switch
                            checked={values.isLabor}
                            onChange={handleChange}
                            name="isLabor"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <label htmlFor="isLabor" className="text-black">
                            Is Labor Active
                          </label>
                          <div className="my-2">
                            <TextField
                              fullWidth
                              id="serviceChargeLabor"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeLabor"
                              placeholder=" Labor Service Charge"
                              label=" Labor Service Charge (in Dollars)"
                              value={
                                values.isLabor ? values.serviceChargeLabor : 0
                              }
                              disabled={!values.isLabor}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="my-2 mb-8 col-md-6">
                          <Switch
                            checked={values.isOneWay}
                            onChange={handleChange}
                            name="isOneWay"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                          <label htmlFor="isOneWay" className="text-black">
                            Is OneWay Active
                          </label>
                          <div className="my-2">
                            <TextField
                              fullWidth
                              id="serviceChargeOneWay"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="serviceChargeOneWay"
                              placeholder="OneWay Service Charge"
                              label=" OneWay Service Charge (in Dollars)"
                              value={
                                values.isOneWay ? values.serviceChargeOneWay : 0
                              }
                              disabled={!values.isOneWay}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <label htmlFor="" className="text-black">
                          NDC SC
                        </label>
                        {NDCServiceCharges?.map((charge, index) => (
                          <div className="flex flex-row gap-3 my-2 mb-8 w-full col-md-4">
                            <TextField
                              labelId="carrier"
                              label="Carrier"
                              value={charge.carrier}
                              // onChange={(e) => handleCarrierChange(index, e)}
                              size="small"
                              placeholder="Carrier"
                              fullWidth
                              variant="outlined"
                              required
                              // disabled
                            />

                            <TextField
                              label="One Way Charge"
                              value={charge.oneWay}
                              // onChange={handleChange}
                              onChange={(e) => handleChangeCharge(index, e)}
                              name="oneWay"
                              type="number"
                              size="small"
                              fullWidth
                              min={0}
                              required
                            />
                            <TextField
                              label="Round Trip Charge"
                              value={charge.round}
                              // onChange={handleChange}
                              onChange={(e) => handleChangeCharge(index, e)}
                              name="round"
                              type="number"
                              size="small"
                              fullWidth
                              min={0}
                              required
                            />
                          </div>
                        ))}
                      </div>
                      <LoadingButton
                        className="mt-8"
                        variant="contained"
                        type="submit"
                        // loading={add?.loading}
                      >
                        Update
                      </LoadingButton>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
          <div className="modal-footer">
            <Button
              onClick={() => setOpenServiceCharge(false)}
              variant="danger"
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateServiceChargeModal;
