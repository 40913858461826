import axios from "axios";
import API_URL from ".";

export const API = axios.create({
  baseURL: API_URL,
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      alert("Session expired. Please log in again.");
      localStorage.removeItem("user");
      localStorage.removeItem("expirationTime");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

// API.interceptors.request.use((config) => {
//   const user = localStorage.getItem("user");
//   if (user?.token) config.headers.Authorization = `Bearer ${user?.token}`;
//   return config;
// });
