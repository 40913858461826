import { Fragment } from "react";

import Shopping from "../../../components/NDC/AirShopping/Shopping";
import {
  TRIP_TYPE_MULTI_CITY,
  TRIP_TYPE_ONE_WAY,
  TRIP_TYPE_ROUND_TRIP,
} from "../../../common/constants";

const FlightGrid = ({
  sortedFlights,
  isLocal,
  isReturn,
  channelType,
  tripType,
  getNDCList,
  renderOneWayFlights,
  renderRoundTripFlights,
  renderMultiCityFlights,
  renderReturnTripFlights,
  queryURl,
  company,
  corporate,
  ndcData,
  setLoading,
  discount,
}) => {
  return (
    <div className="grid grid-cols-1 gap-6 rounded-3xl">
      {sortedFlights?.length < 1 && (
        <div className="flex justify-center items-center h-96">
          <h1 className="text-2xl font-bold">No Flights Found</h1>
        </div>
      )}
      {sortedFlights.map((flight) => {
        const isNDC = flight.channel === "NDC";
        const ndcList = isNDC && getNDCList(flight);

        return isNDC
          ? ndcList &&
              !isLocal &&
              !isReturn &&
              channelType?.includes("NDC") && (
                <Shopping
                  key={flight.id}
                  flight={ndcList}
                  dataSet={ndcData}
                  setLoading={setLoading}
                  query={queryURl}
                  company={company}
                  corporate={corporate}
                  discount={discount}
                />
              )
          : channelType?.includes("GDS") && (
              <Fragment key={flight.id}>
                {tripType === TRIP_TYPE_ONE_WAY && renderOneWayFlights(flight)}
                {tripType === TRIP_TYPE_ROUND_TRIP &&
                  !isReturn &&
                  renderRoundTripFlights(flight)}
              </Fragment>
            );
      })}

      {channelType?.includes("GDS") && (
        <Fragment>
          {tripType === TRIP_TYPE_MULTI_CITY && renderMultiCityFlights()}
          {tripType === TRIP_TYPE_ROUND_TRIP &&
            isReturn &&
            renderReturnTripFlights()}
        </Fragment>
      )}
    </div>
  );
};

export default FlightGrid;
