import { LoadingButton } from "@mui/lab";
import {
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import CloseIcon from "@mui/icons-material/Close";

import React from "react";
import { Modal } from "react-bootstrap";
import { BillingSchema } from "../user.util";

const AddWalletModal = ({ openAddWallet, setOpenAddWallet, handleBilling }) => {
  return (
    <Modal
      className="modal fade"
      show={openAddWallet}
      onHide={setOpenAddWallet}
      size="md"
      centered
      backdrop="static"
    >
      <div className="" role="document">
        <div className="overflow-hidden rounded-2xl">
          <div className="flex justify-between items-center p-4 bg-gray-50 border-b border-gray-200">
            <Typography className="text-2xl font-bold" variant="h5">
              Add To Wallet
            </Typography>
            <IconButton
              onClick={() => setOpenAddWallet(false)}
              size="small"
              sx={{ color: "error.main" }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close text-gray"></i>
            <div className="add-contact-box">
              <Formik
                initialValues={{
                  deposit: "",
                  transaction: "",
                  bank: "",
                }}
                validationSchema={BillingSchema}
                onSubmit={handleBilling}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} className="flex items-end">
                    <div className="flex flex-col">
                      <div className="row">
                        <div className="mb-3 col-md-12">
                          <TextField
                            fullWidth
                            id="bank"
                            name="bank"
                            size="small"
                            value={values.bank}
                            label="Bank"
                            onChange={handleChange}
                            select
                            error={touched.bank && Boolean(errors.bank)}
                            helperText={touched.bank && errors.bank}
                          >
                            <MenuItem value="cbe">CBE</MenuItem>
                            <MenuItem value="awash">Awash Bank</MenuItem>
                            <MenuItem value="abyssinia">
                              Abyssinia Bank
                            </MenuItem>
                            <MenuItem value="amhara">Amhara Bank</MenuItem>
                            <MenuItem value="bunna">Bunna Bank</MenuItem>
                            <MenuItem value="wegagen">Wegagen Bank</MenuItem>
                            <MenuItem value="dashen">Dashen Bank</MenuItem>
                            <MenuItem value="zemen">Zemen Bank</MenuItem>
                            <MenuItem value="oromia">Oromia Bank</MenuItem>
                            <MenuItem value="coop">
                              Cooperative Bank of Oromia
                            </MenuItem>
                            <MenuItem value="short-term-credit">
                              Short-term Credit
                            </MenuItem>
                          </TextField>
                        </div>
                        <div className="mb-3 col-md-12">
                          <TextField
                            fullWidth
                            type="number"
                            id="deposit"
                            size="small"
                            variant="outlined"
                            name="deposit"
                            placeholder="Deposit Amount"
                            label="Deposit Amount"
                            value={values.deposit}
                            onWheel={(e) => e.target.blur()}
                            // disabled={checker?.data?.user?.firstName ?? false}
                            onChange={handleChange}
                            error={touched.deposit && Boolean(errors.deposit)}
                            helperText={touched.deposit && errors.deposit}
                          />
                        </div>
                        <div className="mb-6 col-md-12">
                          <TextField
                            fullWidth
                            id="transaction"
                            type="text"
                            size="small"
                            variant="outlined"
                            name="transaction"
                            placeholder="Transaction ID"
                            label="Transaction ID"
                            value={values.transaction}
                            onChange={handleChange}
                            disabled={values.bank === "short-term-credit"}
                            error={
                              touched.transaction && Boolean(errors.transaction)
                            }
                            helperText={
                              touched.transaction && errors.transaction
                            }
                          />
                        </div>
                        {/* <div className="mb-8 col-lg-6">
                            <TextField
                              fullWidth
                              id="amount"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="amount"
                              // disabled={checker?.data?.user?.phone ?? false}
                              placeholder="Amount"
                              label="Amount"
                              value={values.amount}
                              onChange={handleChange}
                              // error={touched.phone && Boolean(errors.phone)}
                              // helperText={touched.phone && errors.phone}
                              // InputProps={{
                              //   startAdornment: (
                              //     <InputAdornment position="start">
                              //       +251
                              //     </InputAdornment>
                              //   ),
                              // }}
                            />
                          </div> */}
                        {/* <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="walletBalance"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="walletBalance"
                              placeholder="Wallet Balance"
                              label="Wallet Balance"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.walletBalance}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div> */}
                        {/* <div className="mb-8 col-md-6">
                            <TextField
                              fullWidth
                              id="creditLimit"
                              type="number"
                              size="small"
                              variant="outlined"
                              name="creditLimit"
                              placeholder="Credit Limit"
                              label="Credit Limit"
                              // disabled={checker?.data?.user?.email ?? false}
                              value={values.walletBalance}
                              onChange={handleChange}
                              // error={touched.email && Boolean(errors.email)}
                              // helperText={touched.email && errors.email}
                            />
                          </div> */}
                      </div>
                      <LoadingButton
                        className="mt-8"
                        variant="contained"
                        type="submit"
                        // loading={add?.loading}
                      >
                        Add
                      </LoadingButton>
                    </div>
                  </form>
                )}
              </Formik>
              {/* ) : (
                  <>Here</>
                )} */}
            </div>
          </div>
          <div className="modal-footer">
            <Button onClick={() => setOpenAddWallet(false)} variant="danger">
              {" "}
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddWalletModal;
